import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Card,
  Tabs,
  Tab,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { getZTokens,update_expiry } from '../../Services/ConfigService';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast, ToastContainer } from 'react-toastify';
function FutureExpiry() {
    dayjs.extend(customParseFormat);
    const [activeTab, setActiveTab] = useState('NFO-FUT');
    const [zTokensData, setZTokensData] = useState([]);
    const [saveLoading, setSaveLoading] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);
    const [loading,setLoading]=useState(false);
    const [dates, setDates] = React.useState({});
    const fetchZTokens = useCallback(async () => {
        if (activeTab) {
          try {
            setLoading(true);
            const [tokenData, countNum] = await getZTokens(activeTab, searchTerm, page, pageSize);
            setZTokensData(tokenData);
            setCount(countNum);
          } catch (error) {
            console.error('Error fetching tokens: ', error);
          }finally{
            setLoading(false);
          }
        }
      }, [activeTab, searchTerm, page, pageSize]);

      useEffect(() => {
        fetchZTokens().catch(console.error);
      }, [fetchZTokens]);

    const handleChangeTab = (event, newValue) => {
        setSearchTerm('')
        setPage(1)
        setActiveTab(newValue);
      };
      useEffect(() => {
        const initialDates = zTokensData.reduce((acc, stock) => ({
          ...acc,
          [stock.zid]: stock.expiry ? dayjs(stock.expiry) : null
        }), {});
        setDates(initialDates);
      }, [zTokensData]);
    
      const handleDateChange = (zid, newValue) => {
        setDates({
          ...dates,
          [zid]: newValue
        });
      };

      const handleSave = async (zid) => {
        const selectedDate = dates[zid];
        if (selectedDate) {
            // Append the time part to the selected date
            const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD') + 'T23:59:59.000'+'+05:30';
            setSaveLoading(prev => ({ ...prev, [zid]: true })); // Start loading
            try {
                const response = await update_expiry(zid, formattedDate, activeTab);
                toast.success('Update successful!', { autoClose: 2000 });
                console.log('Saving for token:', zid, 'with date:', formattedDate);
            } catch (error) {
                console.error('Error updating expiry:', error);
                toast.error('Update failed!', { autoClose: 2000 });
            } finally {
                setSaveLoading(prev => ({ ...prev, [zid]: false })); // End loading
            }
        } else {
            toast.warning('No date selected', { autoClose: 2000 });
        }
    };
    
    


  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab
            label={
              <span>
                NFO-FUT 
              </span>
            }
            value="NFO-FUT"
          />
          <Tab
            label={
              <span>
                MCX
              </span>
            }
            value="MCX"
          />
        </Tabs>
      </Box>
    </Card>
    <Grid>
    <TextField
              label="Search Name"
              variant="outlined"
              value={searchTerm}
              onChange={(e) =>{
                setSearchTerm(e.target.value);
                setPage(1);
              } }
              sx={{ m: 2, width: '300px' }}
            />
    </Grid>
    <Grid mt={1}>
    {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DemoContainer components={['DateRangePicker']}>
                <DemoItem> */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {zTokensData.map((stock) => (
              <TableRow key={stock.zid}>
                <TableCell>{stock.tradingsymbol}</TableCell>
                <TableCell>
                <DatePicker
  label="Select Date"
  value={dates[stock.zid]}
  onChange={(newValue) => handleDateChange(stock.zid, newValue)}
  renderInput={(params) => <TextField {...params} />}
/>
                </TableCell>
                <TableCell>
                <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave(stock.zid)}
            disabled={saveLoading[stock.zid]}  // Disable the button while loading
        >
            {saveLoading[stock.zid] ? <CircularProgress size={24} /> : 'Save'}
        </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </LocalizationProvider>
      )}
    <Box display="flex" justifyContent="center" mt={1}>
              {/* Pagination component for names list */}
              <Pagination count={Math.ceil(count / pageSize)} page={page} onChange={(event, value) => setPage(value)} color="primary" />
            </Box>
    </Grid>
    <ToastContainer/>
    </Grid>
  )
}

export default FutureExpiry