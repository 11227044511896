import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Tabs,
  Tab,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { getAllAgents, getAllSubAgents } from '../../Services/UserService';
import { getZTokens, getZTokensByAgent, mappingToken,mappingAllTokens,getAllTokenConfig } from '../../Services/ConfigService';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const Config = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiData, setApiData] = useState([]);
  const [zTokensData, setZTokensData] = useState([]);
  const [zTokensAgent, setZTokensAgent] = useState([]);
  const [selected, setSelected] = useState();
  const [options, setOptions] = useState();
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [names, setNames] = useState([]);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [transformedData, setTransformedData] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [role, setRole] = useState('');
  const [category, setCategory] = useState('NSE');
  const [open, setOpen] = useState(false);
  const [pendingCategory, setPendingCategory] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [filteredSelectedNames, setFilteredSelectedNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const[selectAll,setSelectAll]=useState(false);
  const [loading1,setLoading1]=useState(false);
const [loading2,setLoading2]=useState(false);
const [isLoading, setIsLoading] = useState(false);
  const fetchZTokens = useCallback(async () => {
    if (selected) {
      try {
        setLoading1(true);
        const [tokenData, countNum] = await getZTokens(category, searchTerm, page, pageSize);
        setZTokensData(tokenData);
        setCount(countNum);
      } catch (error) {
        console.error('Error fetching tokens: ', error);
      }finally{
        setLoading1(false);
      }
    }
  }, [selected, category, searchTerm, page, pageSize]);

  const fetchZTokensAgent = useCallback(async () => {
    if (selected) {
      try {
        setLoading2(true);
        const agentTokens = await getZTokensByAgent(selected, category);
        const bool = await getAllTokenConfig(selected, category)
        console.log("apii",agentTokens)
        setZTokensAgent(agentTokens.data);
        setSelectAll(bool.all_instruments_present);
        sessionStorage.setItem('agentTokenData', agentTokens.data);
      } catch (error) {
        console.error('Error fetching agent tokens: ', error);
      }finally{
        setLoading2(false);
      }
    }
  }, [selected, category]);

  console.log(selectAll);

  const fetchAgents = useCallback(async () => {
    if (role === 'AU') {
      // Fetch both agents and sub-agents for AU
      try {
        const agents = await getAllAgents('true', agentId, 1, 10, searchQuery);
        const subAgents = await getAllSubAgents('true', agentId, 1, 10, searchQuery);
        const combinedData = [...agents.rows, ...subAgents.rows]; // Combine agents and sub-agents
        setApiData(combinedData);
      } catch (error) {
        console.error('Error fetching agents and sub-agents: ', error);
      }
    } else if (role === 'AG') {
      // Fetch all sub-agents and add 'Myself' for AG
      try {
        const subAgents = await getAllSubAgents('true', agentId, 1, 10, searchQuery);
         const agentsWithMyself = [{ email: sessionStorage.getItem('email'), user_id: agentId }, ...subAgents.rows];
        setApiData(agentsWithMyself,);
      } catch (error) {
        console.error('Error fetching sub-agents: ', error);
      }
    } else if (role === 'SA') {
      // Only 'Myself' option for SA
      setApiData([{ email: sessionStorage.getItem('email'), user_id: agentId }]);
    }
  }, [role, agentId, searchQuery]);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole);
  }, []);

  useEffect(() => {
    const lowercasedFilter = selectedSearchTerm?.toLowerCase();
    const filteredData = selectedNames.filter((item) => item?.name?.toLowerCase().includes(lowercasedFilter));
    setFilteredSelectedNames(filteredData);
  }, [selectedSearchTerm, selectedNames]);

  useEffect(() => {
    fetchZTokens().catch(console.error);
  }, [fetchZTokens]);

  useEffect(() => {
    fetchZTokensAgent().catch(console.error);
  }, [fetchZTokensAgent]);

  useEffect(() => {
    fetchAgents().catch(console.error);
  }, [fetchAgents]);

  const handleChange = (newValue) => {
    setOpen(true);
    setPendingCategory(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setNames([])
    setPage(1);
    setSearchTerm('');
    setZTokensAgent([])
    setSelectedSearchTerm('')
    setCategory(pendingCategory);
    handleClose();
  };
  useEffect(() => {
    setNames(
      zTokensData.map((token) => ({
        id: token.zid,
        name: token.tradingsymbol,
        instrument_type: token.instrument_type,
        exchange: token.segment
      }))
    );
  }, [zTokensData]);

  useEffect(() => {
    console.log("here",
      zTokensAgent.map((item) => ({
        id: item.financial_instrument_id,
        name: item.trading_symbol,
        instrument_type: item.instrument_type,
        exchange: item.exchange
      }))
    );
    setSelectedNames(
      zTokensAgent.map((item) => ({
        id: item.financial_instrument_id,
        name: item.trading_symbol,
        instrument_type: item.instrument_type,
        exchange: item.exchange
      }))
    );
  }, [zTokensAgent]);
console.log("selectedNames",names,"----","agnet",selectedNames)
  useEffect(() => {
    setSelectedNames(transformedData?.filter((tItem) => names.some((nItem) => nItem.id === tItem.id)));
  }, [transformedData]);

  useEffect(() => {
   
      setOptions(
        apiData?.map((user) => ({
          label: user.email + ' (' + user.user_id + ')',
          id: user.user_id
        }))
      );
    
  }, [apiData]);

  useEffect(()=>{
if(role==='SA'&& agentId){
  setSelected(agentId)
}
  },[role,agentId])
  const handleSelect = (event, value) => {
    setAutocompleteValue(value);
    setSelected(value ? value.id : '');
    if (!value || (value && value.id !== selected)) {
      setSelectedNames([]);
    }
  };

  const handleToggle = (value) => {
    const currentIndex = selectedNames.findIndex((name) => name.id === value.id);
    let newSelectedNames = [...selectedNames];

    if (currentIndex === -1) {
      // If not already selected, add to selectedNames
      newSelectedNames.push(value);
    } else {
      // If already selected, remove from selectedNames
      newSelectedNames.splice(currentIndex, 1);
    }

    setSelectedNames(newSelectedNames);
  };

  const handleSave = () => {
    setIsLoading(true);
    const stock = {
      user_id: selected,
      segment: category,
      exchange: category=="NFO-FUT"?"NSE":category,
      stocks: selectedNames
    };
    mappingToken(stock)
      .then((response) => {
        toast.success('Mapping created successfully', {
          autoClose: 2000
        });
      })
      .catch((error) => {
        toast.error('Failed to create Mapping', {
          autoClose: 2000
        });
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };
  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
  };
  const handleSelectAllChange = (event) => {
    const data={
      "user_id": selected,
      "segment": category,
      "exchange": category=="NFO-FUT"?"NSE":category,  
      "add_all": event.target.checked  
      }
    setSelectAll(event.target.checked);
      mappingAllTokens(data) .then((response) => {
        if (response && response.message) {
          toast.success('Mapping created successfully', {
            autoClose: 2000
          });
          fetchZTokensAgent();
          fetchZTokens();
        } else {
          toast.error('Failed to map data. Please try again.', {
            autoClose: 2000
          });
        }
      })
      .catch((error) => {
        console.error('Error mapping data: ', error);
        toast.error('Failed to map data. Please try again.', {
          autoClose: 2000
        });
      });
};
useEffect(()=>{
  
},[selectAll])  
  return (
    <Grid>
        {role !=='SA'&&(
      <Box display="flex" flexDirection="row" justifyContent="space-between" pb={2}>
        <Box>
          <Autocomplete
            disablePortal
            value={autocompleteValue}
            id="combo-box-demo"
            options={options}
            sx={{ width: 350 }}
            onChange={handleSelect}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Agent/SubAgent ID"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.75rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
      
        </Box>
       
        <Box>
        <Button variant="outlined" color="success" onClick={() => handleSave()} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Save Config"}
          </Button>
          <Typography>* Save the config before switching to another tab</Typography>
        </Box>
      </Box>
    )}
      <Tabs value={category} aria-label="category">
        <Tab label="NSE" value="NSE" onClick={() => handleChange('NSE')} />
        <Tab label="FUTURES" value="NFO-FUT" onClick={() => handleChange('NFO-FUT')} />
        <Tab label="CRYPTO" value="CRYPTO" onClick={() => handleChange('CRYPTO')} />
        <Tab label="NASDAQ" value="NASDAQ" onClick={() => handleChange('NASDAQ')} />
        <Tab label="MCX" value="MCX" onClick={() => handleChange('MCX')} />
      </Tabs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-around">
  
  <Typography variant="h4" mt={3}>
    Available
  </Typography>
  {role !=='SA'&& autocompleteValue &&(
  <Box mt={2} display="flex" flexDirection="row" justifyContent="space-around">
  <Checkbox checked={selectAll} onChange={handleSelectAllChange}/>
  <Typography mt={1.5}>Select All</Typography>
  </Box>
  )}
</Box>
            <TextField
              label="Search Names"
              variant="outlined"
              value={searchTerm}
              onChange={(e) =>{
                setSearchTerm(e.target.value);
                setPage(1);
              } }
              sx={{ m: 2, width: '300px' }}
            />
             {loading1 ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    ) : (
      <>
            <List sx={{ height: 400, overflow: 'auto' }}>
              {names.map((name) => (
                <ListItem key={name.id} dense button onClick={() => handleToggle(name)}>
                   {role !=='SA'&&(
                  <Checkbox edge="start" checked={selectedNames.some((selected) => selected.id === name.id)} tabIndex={-1} disableRipple />
                   )}
                  <ListItemText primary={name.name} />
                </ListItem>
              ))}
            </List>
            <Box display="flex" justifyContent="center">
              {/* Pagination component for names list */}
              <Pagination count={Math.ceil(count / pageSize)} page={page} onChange={(event, value) => setPage(value)} color="primary" />
            </Box>
            </>)}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" mt={4}>
                Selected
              </Typography>
            </Box>
            <TextField
              label="Search Selected Names"
              variant="outlined"
              value={selectedSearchTerm}
              onChange={(e) => setSelectedSearchTerm(e.target.value)}
              sx={{ m: 2, width: '300px' }}
            />
             {loading2 ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    ) : (
      <>
            <List sx={{ height: 435, overflow: 'auto' }}>
              {filteredSelectedNames.map((name) => (
                <ListItem key={name.id}>
                  <ListItemText primary={name.name} />
                </ListItem>
              ))}
            </List>
            </>)}
            {/* <Box display="flex" justifyContent="center">
              <Pagination
                count={Math.ceil(count2/ pageSize2)}
                page={page2}
                onChange={(event, value) => setPage2(value)}
                color="primary"
              />
            </Box> */}
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Save the config before changing the tab, If you made any changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Config;
