import React, { useState,useEffect } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { ToastContainer, toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import LockIcon from '@mui/icons-material/Lock';
import { verifyFirstOtp, verifyOtp } from '../../Services/UserConfigService';
function TwoFA() {
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [faSecret, setFaSecret] = useState('');
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get('email');
    const tokenParam = queryParams.get('token');
    const Secret = queryParams.get('mfa_secret');
    const mfa_enabled = queryParams.get('mfa_enabled') === 'true';  // Convert to boolean

    setEmail(decodeURIComponent(emailParam || ''));
    setToken(decodeURIComponent(tokenParam || ''));
    setEnabled(mfa_enabled);

    if(Secret){
        setFaSecret(decodeURIComponent(Secret))
    }
}, []);

  // console.log("abc",enabled)
  const handleChange = (otp) => {
    if (otp.length === 6) {
      setOtp(otp);
      handleSubmit(new Event('submit'), otp);
    } else {
      setOtp(otp);
    }
  };
  const handleSubmit = async (event,otpValue) => {
    event.preventDefault();
  
    // Determine which verification function to call based on the `enabled` state
    const response = await (enabled==true ? verifyOtp(email, otpValue) : verifyFirstOtp(email, otpValue));
  
    // Handle response based on the verification status
    if (response.status === 'Verified') {
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('authToken', token);
      toast.success('OTP verified successfully', { autoClose: 2000 });
      setEnabled(true)
      setFaSecret('')
      setOtp()
      // Redirect after a short delay
      setTimeout(() => {
        window.location.href = '/user-list';
      }, 1000);
    } else if (response[0].status === 'Verification failed') {
      toast.error('Invalid OTP', { autoClose: 2000 });
    }
  };
  

  return (
    <Container maxWidth="xs" sx={{ display: 'flex', height: '98vh', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Box sx={{display:'flex',flexDirection:'row'}}>
          <LockIcon sx={{color:'rgb(255, 120, 117)',fontSize:'80px'}}/>
          <Typography sx={{fontSize:'40px',fontWeight:600, marginTop:2}}>2FA</Typography>
        </Box>
        {!enabled&&(
          <>
        <Box mb={2} mt={2}>
          <QRCode value={faSecret} />
        </Box>
        <Box>
          <Typography>(This QR code is only provided for your initial 2FA setup. It will not be displayed again after this setup process. Please ensure to scan it now )</Typography>
        </Box>
        </>
         )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, display: 'flex' }}>
        <OtpInput
      value={otp}
      onChange={handleChange}
      numInputs={6}
      inputType={'tel'}
      isInputNum={true} // Ensures only numbers can be entered
      shouldAutoFocus={true}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width : '40px',height: '40px',margin: '0 5px',fontSize: '20px', textAlign: 'center', border: '1px solid #ccc', borderRadius: '4px'}}/>}
    />
          {/* <Button type="submit" variant="contained" sx={{ ml: 2 }}>
            Verify
          </Button> */}
        </Box>
      </Box>
      <ToastContainer/>
    </Container>
  );
}

export default TwoFA;
