import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserActivity from './utils/UserActivity';
import UserActivityModal from './pages/UserActivityModal/UserActivityModal';
import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import UserDisableModal from './pages/UserActivityModal/UserDisableModal';

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  const excludedPaths = ['/login'];
  const isActive = !excludedPaths.includes(location.pathname);

  const handleInactive = () => {
    sessionStorage.removeItem('authToken')
    setModalOpen(true);
  };

  // Pass isActive as a parameter to control the hook's behavior
  UserActivity(handleInactive, isActive);

  return (
    <ThemeCustomization>
      <ScrollTop>
      <UserDisableModal />
        <UserActivityModal open={modalOpen} setModalOpen={setModalOpen} />
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;