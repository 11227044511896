
const env=process.env.REACT_APP_API_URL;
const go = process.env.REACT_APP_GO_URL;
export const getUsers = async (user_id) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
    var URL = user_id.split('-')[0]==='AG'?`${env}/users/?user_id=${user_id}`:`${env}/users`
    const response = await fetch(URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};


export const getUsersWithKeyword = async (user_id,keyword) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
    var URL = user_id?.split('-')[0]==='AG'?`${env}/users/searchkeyword/?user_id=${user_id}&keyword=${keyword}`:`${env}/users/searchkeyword/?keyword=${keyword}`
    const response = await fetch(URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};


export const getAllUsers = async (bool, user_id, page_number, page_size, search_keyword) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }

    const queryParams = new URLSearchParams({
      user_role: 'IU',
      is_active: bool,
      oversee_id: user_id,
      page_number: page_number,
      page_size: page_size,
    });

    if (search_keyword !== undefined) {
      queryParams.append('search_keyword', search_keyword);
    }

    const URL = `${env}/users/by/?${queryParams.toString()}`;

    const response = await fetch(URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};

  

  export const getAllAgents = async (bool, user_id, page_number, page_size, search_keyword) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const queryParams = new URLSearchParams({
        user_role: 'AG',
        is_active: bool,
        oversee_id: user_id,
        page_number: page_number,
        page_size: page_size,
      });
      if (search_keyword !== undefined) {
        queryParams.append('search_keyword', search_keyword);
      }
      const URL = `${env}/users/by/?${queryParams.toString()}`;
  
      const response = await fetch(URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAllSubAgents = async (bool, user_id, page_number, page_size, search_keyword) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
    
      const queryParams = new URLSearchParams({
        user_role: 'SA',
        is_active: bool,
        oversee_id: user_id,
        page_number: page_number,
        page_size: page_size,
      });
      if (search_keyword !== undefined) {
        queryParams.append('search_keyword', search_keyword);
      }
      const URL = `${env}/users/by/?${queryParams.toString()}`;
  
      const response = await fetch(URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAllSupport = async (bool, page_number, page_size, search_keyword) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const queryParams = new URLSearchParams({
        is_active: bool,
        page_number: page_number,
        page_size: page_size,
      });
      if (search_keyword !== undefined) {
        queryParams.append('search_keyword', search_keyword);
      }
      var URL = `${env}/getAll-supportUsers/?${queryParams.toString()}`
      const response = await fetch(URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAllCounts = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      var URL = `${env}/users/getCountsByUserStatus/?user_id=${user_id}`;
      const response = await fetch(URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };
  export const getAllAgentCounts = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/agents/getCountsByAgentStatus/?user_id=${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAllSubAgentCounts = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/users/getCountsBySubAgentStatus/?user_id=${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAllSupportCounts = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/support/getCountsBySupportStatus`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const deleteUserById = async (userId) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      
      if (response.status === 204) {
        console.log(`User/Agent with ID ${userId} deleted.`);
      } else {
        console.error('Failed to delete user/agent.');
      }
    } catch (error) {
      console.error('Error deleting user/agent:', error);
      throw error;
    }
  };
  
  export const createUser = async (userData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage||'Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error creating user: ', error);
      throw error;
    }
  };

  export const resetPassword = async (userData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/resetPassword`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage||'Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error creating user: ', error);
      throw error;
    }
  };

  export const copyConfig = async (userData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/copyUserConfig/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage||'Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error copying config: ', error);
      throw error;
    }
  };

  export const copyAgentConfig = async (userData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/copyAgentConfig/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage||'Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error copying config: ', error);
      throw error;
    }
  };
  

  export const updateUser = async (updatedData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  export const updateUserByOversee = async (user_id,is_enabled) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/usersbyoversee/${user_id}/${is_enabled}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };
  
  export const postBalance = async (amount) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/applyBalance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(amount),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text(); // Extract error message from response body
        throw new Error(errorMessage || 'Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error posting balance: ', error);
      throw error;
    }
  };

  export const postAgentBalance = async (amount) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/applyAgentBalance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(amount),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text(); // Extract error message from response body
        throw new Error(errorMessage || 'Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error posting balance: ', error);
      throw error;
    }
  };

  export const updatePassword = async (data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/update-password-userid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating user: ', error);
      throw error;
    }
  };

  export const wallets = async (data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/wallets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error posting balance: ', error);
      throw error;
    }
  };

  export const exchanges = async (data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/exchanges`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error posting exchanges status: ', error);
      throw error;
    }
  };

  export const getWallets = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/wallets/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };
  export const getExchanges = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user/exchanges/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const watchlist = async (marketData) => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        throw new Error("Authentication token not found in session storage");
      }
      const raw = JSON.stringify(marketData);
      const response = await fetch(`${go}/api/user/getPortfolio`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: raw,
        redirect: "follow",
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error("Error fetching data:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  export const makeorder = async (marketData) => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        throw new Error("Authentication token not found in session storage");
      }
      const raw = JSON.stringify(marketData);
      const response = await fetch(`${go}/api/admin/makeOrder`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: raw,
        redirect: "follow",
      });
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        const result = await response.json();
        console.error("Error fetching data:", response.status);
        return result;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };