import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import UnAuthorized from '../pages/Unauthorized/UnAuthorized';
import NotFound from '../pages/NotFound/NotFound';
import ResetPassword from '../pages/authentication/auth-forms/ResetPassword';
import TwoFA from '../pages/authentication/TwoFA';
import AuthGuard from '../authGuard/AuthGuard';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
const UserList = Loadable(lazy(() => import('../pages/Users/UserList')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path:'unauthorized',
      element:<UnAuthorized/>
    },
    {
      path:'reset-password',
      element:<AuthGuard component={ResetPassword} />
    },
    {
      path:'twofactor-authentication',
      element:<TwoFA/>
    },
    {
      path:'*',
      element:<NotFound/>
    }
  ]
};

export default LoginRoutes;
