import { saveAs } from 'file-saver';
const env=process.env.REACT_APP_API_URL;


export const downloadTradesReport = async (userId, startDate, endDate, exchange) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      var URL = `${env}/trades/excel/${userId}?`;
      if (startDate) URL += `start_date=${startDate}&`;
      if (endDate) URL += `end_date=${endDate}&`;
      if (exchange) URL += `exchange=${exchange}`;
  
      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const filename = `trades_report_${userId}.xlsx`;
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  };
  

  export const downloadLoginReport = async (userId, startDate, endDate) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      var URL = `${env}/user/auth/logs/download/${userId}?`;
      if (startDate) URL += `start_date=${startDate}&`;
      if (endDate) URL += `end_date=${endDate}&`;

  
      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const filename = `login_report_${userId}.xlsx`;
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  };
  

  export const downloadTransactionReport = async (userId,exchange, startDate, endDate) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      var URL = `${env}/transactions/download/${userId}?`;
      if (startDate) URL += `start_date=${startDate}&`;
      if (endDate) URL += `end_date=${endDate}&`;
      if (exchange) URL += `exchange=${exchange}`;
  
      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const filename = `transaction_report_${userId}.xlsx`;
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  };
  