import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Card,
  Tabs,
  Tab,
  DialogTitle, Table,
  TableBody,
  TableCell,
  Autocomplete,
  TableRow,
  DialogContentText,
} from '@mui/material';
import { CardContent, Typography } from '@mui/material';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getAllTickets,getTicketCounts, updateTicket } from '../../Services/TicketService';
import { jwtDecode } from "jwt-decode";
import { getAllSubAgents, getAllSupport } from '../../Services/UserService';
import TablePagination from '@mui/material/TablePagination';
import { set } from 'lodash';
function TicketList() {
  const [searchQuery, setSearchQuery] = useState();
  const [searchQuerydrop, setSearchQuerydrop] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [openNew, setOpenNew] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiCounts,setApiCount]=useState();
  const [activeTab, setActiveTab] = useState('Pending');
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedUserId, setSelectedUserId] = useState();
  const[agentId,setAgentId]=useState();
const[options,setOptions]=useState([]);
const[page,setPage]=useState(0);
const [pageSize,setPageSize]=useState(10)
const[count,setCount]=useState(0);
const[loading,setLoading]=useState(false);
const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(()=>{
    //fetchAgents();
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
  },[])
  const fetchAgents = async () => {
    try {
      const data = await getAllSupport(true, 1,10, searchQuerydrop);
      setOptions(data?.data.map((user) => ({
        label: user.email,
        id: user.user_id
      })));
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    }
  };
  const fetchTickets = async (status,searchQuery,page,page_size) => {
    try {
      setLoading(true);
      const data = await getAllTickets(status,agentId,searchQuery,page+1,page_size);
      setApiData(data?.tickets);
      setCount(data?.total_count);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching users: ', error);
    }finally{
      setLoading(false);
    }
  };
  const fetchCounts = async () => {
    try {
      const data = await getTicketCounts(agentId);
      setApiCount(data);
    } catch (error) {
      console.error('Error fetching Counts: ', error);
    }
  };
  const handleChangeTab = (event, newValue) => {
    setApiData([]);
    setActiveTab(newValue);
    fetchTickets(newValue,searchQuery,page,pageSize);
  };
  const createData = (name, value) => {
    return { name, value };
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  const formatAgentId = (agentId) => agentId ? agentId : "Not Assigned";
  
  const selectedRowData = selectedRow
  ? Object.entries(selectedRow)
      .filter(([key]) => key !== 'deleted_at' && key !== 'user_type' && key !== 'actions')
      .map(([key, value]) => {
        if (key === 'created_at' || key === 'updated_at') {
          value = formatDate(value);
        }
        if (key === 'agent_id') {
          value = formatAgentId(value);
        }
        return { name: key, value };
      })
  : [];

  const handleOpen = (row) => {
    console.log(row)
    setSelectedRow(row);
    setOpen(true);
  };

  useEffect(()=>{
    if(agentId){
      fetchCounts();
      fetchTickets(activeTab, searchQuery, page, pageSize);
      fetchAgents();
    }
  },[agentId, activeTab, searchQuery, page, pageSize])

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'user_id',
      headerName: 'Requested by',
      flex: 1
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      flex: 1,
      renderCell: (params) => (params.value ? formatDate(params.value) : null)
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Button variant="outlined" color="primary" onClick={() => handleOpen(params.row)}>
          View
        </Button>
      )
    }
  ];
  const handleSelection = (event, value) => {
    setSelectedUserId(value ? value.id : '');
  };

  const handleAssign=(status)=>{
    let data = {
      "status": status
  };

  if (status === "Assigned") {
      data.agent_id = selectedUserId;
  }
    console.log(selectedRowData, data);
     updateTicket(selectedRowData[0].value, data)
     .then((response) => {
      toast.success("Status updated successfully",{
        autoClose: 2000,
      });
      setTimeout(() => {
        setConfirmOpen(false)
        setOpen(false)
      }, 1000);
      fetchCounts();
      fetchTickets(activeTab,searchQuery,page,pageSize);
     }).catch((error) => {
      toast.error("Failed to update Status",{
        autoClose: 2000,
      });
    });
  }
  return (
    <>
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} mb={2} >
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Box
                  sx={{
                    mr: 3,
                    bgcolor: '#91d5ff',
                    color: 'white',
                    borderRadius: '50%',
                    width: '56px',
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <TollOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="h4" component="div">
                   {apiCounts?.Assigned+apiCounts?.Closed+apiCounts?.Pending}
                  </Typography>
                  <Typography variant="subtitle1">Total Tickets</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Box
                  sx={{
                    mr: 3,
                    bgcolor: '#ff7875',
                    color: 'white',
                    borderRadius: '50%',
                    width: '56px',
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <ConfirmationNumberOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="h4" component="div">
                    {apiCounts?.Pending}
                  </Typography>
                  <Typography variant="subtitle1">New Tickets</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Box
                  sx={{
                    mr: 3,
                    bgcolor: '#ffd666',
                    color: 'white',
                    borderRadius: '50%',
                    width: '56px',
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <PendingActionsOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="h4" component="div">
                    {apiCounts?.Assigned}
                  </Typography>
                  <Typography variant="subtitle1">Assigned Tickets</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Box
                  sx={{
                    mr: 3,
                    bgcolor: '#95de64',
                    color: 'white',
                    borderRadius: '50%',
                    width: '56px',
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <LockOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="h4" component="div">
                    {apiCounts?.Closed}
                  </Typography>
                  <Typography variant="subtitle1">Closed Tickets</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
            <Tab label={<span>New Tickets</span>} value="Pending" />
            <Tab label={<span>Assigned Tickets</span>} value="Assigned" />
            <Tab label={<span>Closed Tickets</span>} value="Closed" />
          </Tabs>
        </Box>
      </Card>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mt={1}>
        <Grid>
        <TextField
        sx={{ ml: 1, flex: 1 }}
        placeholder="search"
        inputProps={{ 'aria-label': 'search withName' }}
        onChange={(e)=>setSearchQuery(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '20px' }} aria-label="search">
        <SearchIcon fontSize='medium'/>
      </IconButton>
        </Grid>
      </Grid>
      <Grid mt={2}>
      <DataGrid
          rows={apiData}
          columns={columns}
          getRowId={(data) => data?.id}
          autoHeight={apiData?.length === 0}
          sx={{ maxWidth: '97%', maxHeight: apiData?.length > 0 ? '90vh' : 'auto', minHeight: apiData?.length ? '400px' : '100px' }}
          hideFooterPagination
          hideFooter='true'
          loading={loading}
        />
         <Box display={"flex"} justifyContent={"flex-end"}>
         <TablePagination
       component="div"
       count={count}
       page={page}
       onPageChange={handleChange}
       rowsPerPage={pageSize}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />            </Box>
        </Grid>
    </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle>
        <Typography variant="h3" textAlign="center">
          Ticket Details
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Table aria-label="simple table">
          <TableBody>
            {selectedRowData.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" width={300}>
                  {row.name.charAt(0).toUpperCase() +
                    row.name
                      .slice(1)
                      .replace(/([A-Z])/g, ' $1')
                      .trim() +
                    ' : '}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {activeTab==="Pending"&&(
          <>        
          <Box display='flex' justifyContent='center' mt={3}>
          <Autocomplete
            disablePortal
            id="agent-selection"
           options={options}
            getOptionLabel={(option) => option.label}
            onChange={handleSelection}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select Support User" />}
          />
        </Box>
      
        <Box display='flex' justifyContent='right' mt={3}>
        <Button variant="outlined" onClick={()=>handleAssign("Assigned")} disabled={!selectedUserId}>
        Assign Agent
      </Button>
      </Box>
      </>

        )}
         {activeTab==="Assigned"&&(
           <Box display='flex' justifyContent='right' mt={3}>
           <Button variant="outlined" onClick={()=>setConfirmOpen(true)}>
           Close Ticket
         </Button>
         </Box>
         )}
       
      </DialogContent>
    </Dialog>
    <Dialog
        open={confirmOpen}
        onClose={() =>setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Ticket Closure"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close this ticket?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={()=> handleAssign("Closed")} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    <ToastContainer/>
    </>
  );
}

export default TicketList;
