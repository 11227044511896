import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Card,
  Tabs,
  Tab,
  Badge,
  DialogTitle
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditIcon from '@mui/icons-material/Edit';
import { getAllSupport,updateUser,getAllSupportCounts,deleteUserById } from '../../Services/UserService';
import { jwtDecode } from "jwt-decode";
import SupportEditModal from './SupportEditModal';
import SupportAddModal from './SupportAddModal';
import TablePagination from '@mui/material/TablePagination';

function SupportList() {
  const [searchQuery, setSearchQuery] = useState();
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [apiCounts,setApiCount]=useState();
  const [rowData,setRowData]=useState();
  const [Dis, setDis] = useState(false);
  const [Act, setAct] = useState(false);
  const [Del, setDel] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const[ids,setId]=useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const[page,setPage]=useState(0);
  const [pageSize,setPageSize]=useState(10)
  const[count,setCount]=useState(0);

  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  },[])

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const fetchUsers = async (activeTab,searchQuery, page , pageSize) => {
    try {
      let userData;
      if (activeTab === "active") {
        userData = await getAllSupport(true, page+1, pageSize, searchQuery);
      } else if (activeTab === "disable") {
        userData = await getAllSupport(false, page+1, pageSize, searchQuery);
      }
     setApiData(userData?.data);
    setCount(userData?.count);
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };
  const fetchCounts = async () => {
    try {
      const data = await getAllSupportCounts();
      setApiCount(data);
    } catch (error) {
      console.error('Error fetching Counts: ', error);
    }
  };
  const handleDeleteUser = async (userId) => {
    try {
      await deleteUserById(userId);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  useEffect(() => {
      fetchUsers(activeTab,searchQuery, page, pageSize);  
    fetchCounts();
  }, [activeTab, ids]);

  const activeColumns = [
    {
      field: 'user_id',
      headerName: 'User Id',
      flex: 1
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDisable(params.row)}>
            <Tooltip title="Disable" placement="top">
              <VisibilityOffIcon style={{ color: '#ff7875' }} />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleEdit(params.row)}>
            <Tooltip title="Edit" placement="top">
              <EditIcon style={{ color: '#ffd666' }} />
            </Tooltip>
          </IconButton>
        </>
      )
    }
  ];
  const disableColumns = [
    {
      field: 'user_id',
      headerName: 'User Id',
      flex: 1,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleActivate(params.row)}>
            <Tooltip title="Activate" placement="top">
              <VisibilityIcon style={{ color: '#95de64' }} />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row)}>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon style={{ color: '#ff7875' }} />
            </Tooltip>
          </IconButton>
        </>
      )
    }
  ];
  const Disable = (id) => {
    const data={
      user_id:id,
      is_active:"false"
    }
    updateUser(data)
      .then((response) => {
        setDis(false);
        fetchUsers(activeTab,searchQuery, page, pageSize);
      fetchCounts();
        toast.success("User disabled successfully",{
          autoClose: 2000,
        });
       
      })
      .catch((error) => {
        toast.error("Failed to disable User",{
          autoClose: 2000,
        });
      });
  };

  const handleActivate=(row)=>{
    setAct(true);
    setRowData(row);
  }
  const handleDisable=(row)=>{
    setDis(true);
    setRowData(row);
  }
  const handleDelete =(row)=>{
    setDel(true);
    setRowData(row);
  }
  const Activate = (id) => {
    const data={
      user_id:id,
      is_active:"true"
    }
    updateUser(data)
      .then((response) => {
        setAct(false);
        fetchUsers(activeTab,searchQuery, page, pageSize);
        fetchCounts();
        toast.success("Support User activated successfully",{
          autoClose: 2000,
        });
       
      })
      .catch((error) => {
        toast.error("Failed to activate Support User",{
          autoClose: 2000,
        });
      });
  };
 
  const Delete = (id) => {
    handleDeleteUser(id)
      .then((response) => {
        setDel(false);
        fetchUsers(activeTab,searchQuery, page, pageSize);
        fetchCounts();
        toast.success("Support User Deleted successfully",{
          autoClose: 2000,
        });
       
      })
      .catch((error) => {
        toast.error("Failed to delete Support User",{
          autoClose: 2000,
        });
      });
  };
  const handleEdit = (data) => {
    setOpenEdit(true);
    setData(data);
  };

  const handleModalClose = () => {
    fetchUsers(activeTab,searchQuery, page, pageSize);
    fetchCounts();
    setOpenNew(false);
    setOpenEdit(false);

  };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab
              label={
                <span>
                  Active <Badge badgeContent={apiCounts?.active_count || 0} color="primary" style={{ marginLeft: '15px' }}  max={99999} showZero/>
                </span>
              }
              value="active"
            />
            <Tab
              label={
                <span>
                  Disable
                  <Badge badgeContent={apiCounts?.inactive_count || 0} color="primary" style={{ marginLeft: '15px' }} max={99999} showZero/>
                </span>
              }
              value="disable"
            />
          </Tabs>
        </Box>
      </Card>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mt={3}>
        <Grid>
          <TextField
            sx={{ ml: 1 }}
            placeholder="search "
            inputProps={{ 'aria-label': 'search with First Name' }}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: '20px' }} aria-label="search">
            <SearchIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid>
          <Button variant="outlined" color="success" onClick={() => setOpenNew(true)}>
          Add New Support User
          </Button>
        </Grid>
      </Grid>
      {activeTab === 'active' && (
        <>
        <DataGrid
          rows={apiData}
          columns={activeColumns}
          getRowId={(data) => data?.user_id}
          autoHeight={apiData?.length === 0}
          sx={{ maxWidth: '97%', maxHeight: apiData?.length > 0 ? '90vh' : 'auto', minHeight: apiData?.length ? '400px' : '100px' }}
          hideFooterPagination
        hideFooter='true'
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
         <TablePagination
       component="div"
       count={count}
       page={page}
       onPageChange={handleChange}
       rowsPerPage={pageSize}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />            </Box>
     </>
      )}
      {activeTab === 'disable' && (
        <>
        <DataGrid
          rows={apiData}
          columns={disableColumns}
          getRowId={(data) => data?.user_id}
          autoHeight={apiData?.length === 0}
          sx={{ maxWidth: '97%', maxHeight: apiData?.length > 0 ? '90vh' : 'auto', minHeight: apiData?.length ? '400px' : '100px' }}
          hideFooterPagination
        hideFooter='true'
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
         <TablePagination
       component="div"
       count={count}
       page={page}
       onPageChange={handleChange}
       rowsPerPage={pageSize}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />            </Box>
     </>
      )}
      <Dialog open={openNew} onClose={() => setOpenNew(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Add New Support User</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenNew(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <SupportAddModal modalHandleCallback={handleModalClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Edit Support User</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenEdit(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <SupportEditModal openEdit={openEdit} data={data} modalHandleCallback={handleModalClose} />
        </DialogContent>
      </Dialog>
     
      <Dialog open={Del} onClose={() => setDel(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Delete {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setDel(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Delete(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={Dis} onClose={() => setDis(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Disable {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setDis(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Disable(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={Act} onClose={() => setAct(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Activate {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setAct(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Activate(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
      <ToastContainer/>
    </Grid>

  );
}

export default SupportList;
