import { useEffect } from 'react';

const UserActivity = (onInactive, isActive, timeout = Number(process.env.INACTIVE_TIME ||  900000)) => { // Defaulting to 5 minutes
    useEffect(() => {
        if (!isActive) {
            return;
        }

        let timer = setTimeout(onInactive, timeout);
        const resetTimer = () => {

            clearTimeout(timer);
            timer = setTimeout(onInactive, timeout);
        };

        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('keydown', resetTimer);
        document.addEventListener('click', resetTimer);

        return () => {
            clearTimeout(timer);
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('keydown', resetTimer);
            document.removeEventListener('click', resetTimer);
        };
    }, [onInactive, isActive, timeout]); // Ensure useEffect is triggered correctly on dependency changes
};

export default UserActivity;
