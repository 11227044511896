import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery,Grid,Typography,MenuItem, Select, FormControl, InputLabel } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { jwtDecode } from 'jwt-decode';
// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getWalletList } from '../../../Services/WalletConfigService';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatAndMultiplyValueAssetItem } from '../../../utils/formatAndMultiplyValue';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const gridSpacing = matchDownSM ? 2 : 1;
  const gridXS = matchDownSM ? 6 : 2;  
  const [marketInfo,setMarketInfo] =useState({
    NSE: { balance: 0, charges: 0 },
    NASDAQ: { balance: 0, charges: 0 },
    CRYPTO: { balance: 0, charges: 0 },
    MCX: { balance: 0, charges: 0 }
  });
  const [selectedMarket, setSelectedMarket] = useState('');
  const [details, setDetails] = useState({ balance: 0, charges: 0 });
  const [role, setRole] = useState();
  const handleChange = (event) => {
    const market = event.target.value;
    setSelectedMarket(market);
    setDetails(marketInfo[market]);
  };
  const [agentId, setAgentId] = useState();
  const[apidata,setApiData]=useState();
  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';
  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole)
      },[])
      useEffect(()=>{
        if (agentId && !agentId.startsWith('AU')) {
          fetchWallets(agentId);
        }
      },[agentId])
  const fetchWallets = async (user_id) => {
    try {
      const datas = await getWalletList(user_id);
      const marketInfo = datas.wallets.reduce((acc, wallet) => {
        acc[wallet.exchange] = {
          balance: wallet.balance,
          charges: wallet.charges
        };
        return acc;
      }, {});
  
      setMarketInfo(marketInfo);
    } catch (error) {
      console.error('Error fetching wallets data: ', error);
    }
  };
  useEffect(() => {
    if (selectedMarket) {
      setDetails(marketInfo[selectedMarket]);
    }
  }, [marketInfo, selectedMarket]); 
  // common header
 // common header
const mainHeader = (
  <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <IconButton
      disableRipple
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      color="secondary"
      sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
    >
      {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </IconButton>
    {role!=="AU"&&(
    <Grid container spacing={3} alignItems="center"  justifyContent={'center'} ml={2}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="market-select-label">Market</InputLabel>
          <Select
            labelId="market-select-label"
            id="market-select"
            value={selectedMarket}
            label="Market"
            onChange={handleChange}
          >
            {Object.keys(marketInfo).map((market) => (
              <MenuItem key={market} value={market}>{market}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {selectedMarket && (
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Balance:{selectedMarket=="NSE" || selectedMarket=="MCX"?"₹":"$"}{details.balance.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Brokerage:{selectedMarket=="NSE" || selectedMarket=="MCX"?"₹":"$"}{details.charges.toFixed(6).replace(/(\.\d*?[1-9])0+$/, '$1')}</Typography>
          </Grid>
          <Grid>
          <IconButton onClick={()=>fetchWallets(agentId)}>
        <RefreshIcon sx={{mt:2}} />
      </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
    )}
    <HeaderContent />
  </Toolbar>
);


  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
