import React from 'react'
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import noAccess from '../../assets/images/users/no-access.png';
function UnAuthorized() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-3); // Navigate back to the previous page
    };
  
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            animation: 'shake 0.5s',
            '@keyframes shake': {
              '0%, 100%': { transform: 'translateX(0)' },
              '20%, 60%': { transform: 'translateX(-10px)' },
              '40%, 80%': { transform: 'translateX(10px)' },
            },
          }}
        >
          <img src={noAccess} width='200px' height='200px'/>
          <Typography variant="body1">
            Sorry, you do not have access to this page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            sx={{ mt: 3 }}
          >
            Go Back
          </Button>
        </Box>
      </Container>
    );
  };
  

export default UnAuthorized