import React from 'react';
import { useNavigate } from 'react-router-dom';
// Authentication function (replace this with your actual authentication logic)
const checkAuthentication = () => {
  // Assume true for authenticated user. Replace with your actual logic.
  var token = sessionStorage.getItem('authToken');
  if(token)
  return true
  return false;
};
const AuthGuard = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!checkAuthentication()) {
      // Redirect the user to the login page if not authenticated
      navigate('/login');
    }
  });
  return checkAuthentication() ? <Component {...rest} /> : null;
};
export default AuthGuard;