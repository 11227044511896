import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, TextField, Grid, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getTimings, updateTimings } from '../../Services/HolidayService';
import { toast, ToastContainer } from 'react-toastify';
function ManageTiming() {
  const [times, setTimes] = useState([]);
  const [edit, setEdit] = useState({});

  useEffect(() => {
    fetchTimes();
  }, []);

  const fetchTimes = async () => {
    try {
        const records = await getTimings();
        setTimes(records);
    } catch (error) {
      console.error('Failed to fetch times:', error);
    }
  };

  const handleTimeChange = (index, value, key) => {
    const newTimes = [...times];
    newTimes[index][key] = value.format('HH:mm:ss');
    setTimes(newTimes);
  };

  const toggleEdit = (index) => {
    setEdit({
      ...edit,
      [index]: !edit[index]
    });
  };

  const saveEdit = async (index) => {
    updateTimings(times)
     .then((response) => {
     
        toast.success('Timings saved successfully', {
          autoClose: 2000
        });
        setTimeout(() => {
            toggleEdit(index);
            fetchTimes();
          }, 1000);
      })
      .catch((error) => {
        toast.error('Failed to save Timings', {
          autoClose: 2000
        });
      });
  
    
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center"> {/* Full screen height */}
      <Card sx={{ m: 2, width: '80vw', maxWidth: 900 }}> {/* Responsive card width */}
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            {times.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Typography variant="h5" mb={2}>
                  {item.exchange}
                </Typography>
                {!edit[index] ? (
                  <Typography variant="h6" color="text.secondary">
                    {item.start_time} - {item.end_time}
                  </Typography>
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                      <TimePicker
                        label="From"
                        value={dayjs('2022-01-01 ' + item.start_time)}
                        onChange={(newTime) => handleTimeChange(index, newTime, 'start_time')}
                        renderInput={(params) => <TextField {...params} disabled/>}
                        ampm={false}
                      />
                      <TimePicker
                        label="To"
                        value={dayjs('2022-01-01 ' + item.end_time)}
                        onChange={(newTime) => handleTimeChange(index, newTime, 'end_time')}
                        renderInput={(params) => <TextField {...params} disabled/>}
                        ampm={false}
                      />
                    </div>
                  </LocalizationProvider>
                )}
                <br/>
                <Button onClick={() => toggleEdit(index)}>{edit[index] ? 'Cancel' : 'Edit'}</Button>
                {edit[index] && <Button onClick={() => saveEdit(index)}>Save</Button>}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <ToastContainer/>
    </Box>
  );
}

export default ManageTiming;
