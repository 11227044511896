import React, { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Button
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { createHoliday, updateHoliday } from '../../Services/HolidayService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditHoliday(props) {
  const { modalHandleCallback, openEdit, data } = props;
  const [selectedMarket, setSelectedMarket] = useState();
  const [date, setDate] = useState(null);
  const [isFullDay, setIsFullDay] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeError, setTimeError] = useState('');
  const [type, setType] = useState('Holiday');
  const [zone, setZone] = useState('IST');
  const[reason,setReason]=useState();
  useEffect(() => {
    if (openEdit && data) {
      setSelectedMarket(data.market); // Assuming market can have multiple selections but data provides one
      setDate(dayjs(data.date)); // Convert date string to dayjs object
      setIsFullDay(data.fullday);
      setType(data.type);
      setReason(data.reason);
      setZone(data.timezone);
      // Convert time strings to dayjs objects, only if not full day
      if (!data.fullday) {
        setStartTime(data.starttime ? dayjs(`2024-03-14T${data.starttime}`) : null); // Use a dummy date for time conversion
        setEndTime(data.endtime ? dayjs(`2024-03-14T${data.endtime}`) : null);
      }
    }
  }, [openEdit, data]);
  console.log(data);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setDate(newValue);
  };

  const handleTimeChange = (newValue, type) => {
    setTimeError('');

    if (type === 'start') {
      setStartTime(newValue);
      if (endTime && newValue && dayjs(endTime).isSameOrBefore(dayjs(newValue))) {
        setEndTime(null);
      }
    } else if (type === 'end') {
      if (startTime && newValue && dayjs(newValue).isAfter(dayjs(startTime))) {
        setEndTime(newValue);
      } else {
        setTimeError('End time must be greater than start time.');
      }
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = () => {
    let formData = {
      timeZone:zone,
      market: selectedMarket,
      date: date ? date.format('YYYY-MM-DD') : '',
      reason:reason,
      fullday: isFullDay,
      type: type
    };

    if (!isFullDay) {
      formData = {
        ...formData,
        startTime: startTime ? startTime.format('HH:mm:ss') : '',
        endTime: endTime ? endTime.format('HH:mm:ss') : ''
      };
    }

    updateHoliday(formData,data?.id)
      .then((response) => {
        toast.success('updated successfully', {
          autoClose: 2000
        });
        setEndTime(null);
        setStartTime(null);
        setDate(null);
        setSelectedMarket([]);
        setTimeout(() => {
          props.modalHandleCallback();
        }, 1000);
      })
      .catch((error) => {
        toast.error('Failed to updat', {
          autoClose: 2000
        });
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} sm={4}>
            <DatePicker label="Date" value={date} onChange={handleStartDateChange} renderInput={(params) => <TextField {...params} />} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select labelId="type-select-label" id="type-select" value={type} label="Type" onChange={handleTypeChange}>
                <MenuItem value="Holiday">Holiday</MenuItem>
                <MenuItem value="Maintenance">Maintenance</MenuItem>
              </Select>
            </FormControl>
          </Grid> 
          <Grid item xs={12} sm={4}>
          <TextField id="outlined-basic" value={reason} label="Comments" variant="outlined" onChange={(e)=>setReason(e.target.value)} required/>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isFullDay} onChange={(e) => setIsFullDay(e.target.checked)} />}
                label="Full Day"
              />
            </FormGroup>
          </Grid>
          {!isFullDay && (
            <>
              <Grid item xs={6}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => handleTimeChange(newValue, 'start')}
                  renderInput={(params) => <TextField {...params} />}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={(newValue) => handleTimeChange(newValue, 'end')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!timeError}
                      helperText={timeError}
                      disabled={!startTime} // Disable until a start time is selected
                    />
                  )}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
                {timeError && <Typography color="error">{timeError}</Typography>}
              </Grid>
            </>
          )}
        </LocalizationProvider>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Button type="submit" variant="outlined" color="success" onClick={handleSubmit}>
          Update
        </Button>
      </Grid>
      <ToastContainer />
    </>
  );
}

export default EditHoliday;
