import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import AgentList from '../pages/Agents/AgentList';
import Reports from '../pages/Reports/Reports';
import TicketList from '../pages/Ticketing/TicketList';
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';
import ChangePassword from '../pages/authentication/ChangePassword';
import Config from '../pages/Config/Config';
import PaymentsList from '../pages/Payments/PaymentsList';
import AuthGuard from '../authGuard/AuthGuard';
import SubAgentList from '../pages/Sub Agents/SubAgentList';
import PositionSummary from '../pages/PositionSummary/PositionSummary';
import SupportList from '../pages/SupportUsers/SupportList';
import CalenderList from '../pages/Calender/CalenderList';
import UserConfig from '../pages/userConfig/UserConfig';
import ManageTiming from '../pages/ManageTiming/ManageTiming';
import AgentTransactions from '../pages/AgentTransactions/AgentTransactions';
import BrokerageReport from '../pages/Brokerage Report/BrokerageReport';
import WeeklyPortfolio from '../pages/WeeklyPortfolio/WeeklyPortfolio';
import FutureExpiry from '../pages/FutureExpiry/FutureExpiry';
import ClosingPrice from '../pages/ClosingPrice/ClosingPrice';
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));

// render - login
const UserList = Loadable(lazy(() => import('../pages/Users/UserList')));
const isAuthorized = (allowedRoles) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    
    const decodedToken = jwtDecode(token);
    return allowedRoles.includes(decodedToken.UserRole);
  } catch (error) {

    return false;
  }
};

const MainRoutes = {
  path: '/',
  element: <AuthGuard component={MainLayout} />,
  children: [
    {
      path: '/user-list',
      element: isAuthorized(['AU', 'AG', 'SA','SU']) ? <AuthGuard component={UserList} /> : <Navigate to="/unauthorized" />
    },
    {
      path: '/agent-list',
      element: isAuthorized(['AU']) && !isAuthorized(['AG','SA','SU']) ? <AuthGuard component={AgentList} /> : <Navigate to="/unauthorized" />
    },
    {
      path: '/subagent-list',
      element: isAuthorized(['AU', 'AG']) && !isAuthorized(['SA','SU']) ? <AuthGuard component={SubAgentList} /> : <Navigate to="/unauthorized" />
    },
    {
      path: '/position-summary',
      element: isAuthorized(['AU', 'AG', 'SA']) ? <AuthGuard component={PositionSummary} /> : <Navigate to="/unauthorized" />
    },
    {
      path: '/weekly-portfolio',
      element: isAuthorized(['AU', 'AG', 'SA']) ? <AuthGuard component={WeeklyPortfolio} /> : <Navigate to="/unauthorized" />
    },
    {
      path: '/reports',
      element: isAuthorized(['AU', 'AG', 'SA','SU']) ? <AuthGuard component={Reports} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/tickets',
      element: isAuthorized(['AU', 'AG', 'SA','SU']) ? <AuthGuard component={TicketList} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/futures-expiry',
      element: isAuthorized(['AU', 'AG']) ? <AuthGuard component={FutureExpiry} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/closing-price',
      element: isAuthorized(['AU', 'AG']) ? <AuthGuard component={ClosingPrice} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/agent-transactions',
      element: isAuthorized(['AU', 'AG', 'SA']) ? <AuthGuard component={AgentTransactions} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/brokerage-report',
      element: isAuthorized(['AU', 'AG']) ? <AuthGuard component={BrokerageReport} /> : <Navigate to="/unauthorized" />
    },
    {
      path:'/support-list',
      element:isAuthorized(['AU', 'AG', 'SA',]) ? <AuthGuard component={SupportList} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/config',
      element:isAuthorized(['AU', 'AG','SA']) ?  <AuthGuard component={Config} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/user-config',
      element:isAuthorized(['AU', 'AG','SA']) ?  <AuthGuard component={UserConfig} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/payments',
      element: isAuthorized(['AU', 'AG', 'SA',]) ?  <AuthGuard component={PaymentsList} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/Manage-Calender',
      element: isAuthorized(['AU', 'AG']) ?  <AuthGuard component={CalenderList} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/manage-timing',
      element: isAuthorized(['AU']) ?  <AuthGuard component={ManageTiming} />: <Navigate to="/unauthorized"/>
    },
    {
      path:'/change-password',
      element: <AuthGuard component={ChangePassword} />
    }
  ]
};

export default MainRoutes;
