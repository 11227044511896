import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  IconButton,
  Tabs,
  Card,
  Tab,
  Badge,
  TextField,
  Tooltip
} from '@mui/material';
import { getApprovalTranscations, getTranscationsCounts, updateTrans } from '../../Services/TransactionService';
import { jwtDecode } from 'jwt-decode';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablePagination from '@mui/material/TablePagination';
import { formatAndMultiplyValueAssetItem } from '../../utils/formatAndMultiplyValue';

function PaymentsList() {
  const [searchQuery, setSearchQuery] = useState();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [apiCounts, setApiCounts] = useState([]);
  const [ids, setId] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [activeTab, setActiveTab] = useState('Pending');
  const [isApproving, setIsApproving] = useState(false);
  const[page,setPage]=useState(0);
  const [pageSize,setPageSize]=useState(10)
  const[count,setCount]=useState(0);
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return;
    const decodedToken = jwtDecode(token); // Assumed jwt-decode is the correct function
    setId(decodedToken.UserID); // Updated to use setIds
  }, []);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const fetchTransactions = async (user_id, tab,page,page_size,search) => {
    try {
      const data = await getApprovalTranscations(user_id, tab,page+1,page_size,search);
      setApiData(data?.data);
      setCount(data?.count);
    } catch (error) {
      console.error('Error fetching transactions: ', error);
    }
  };

  // const updateTransactions = async (id, status) => {
  //   try {
  //     updateTrans(id, status).then((response) => {
  //       toast.success('Transaction updated successfully', {
  //         autoClose: 2000
  //       });
  //       fetchTransactions(ids, activeTab,page,pageSize,searchQuery);
  //       fetchCounts(ids);
  //     });
  //   } catch (error) {
  //     toast.error('Failed to update Transaction', {
  //       autoClose: 2000
  //     });
  //   }
  // };

  const fetchCounts = async (oversee_id) => {
    try {
      const data = await getTranscationsCounts(oversee_id);
      setApiCounts(data);
    } catch (error) {
      console.error('Error fetching transactions: ', error);
    }
  };

  useEffect(() => {
    const fetchTransactionsAndCounts = async () => {
      if (ids) {
        await fetchTransactions(ids, activeTab,page,pageSize,searchQuery);
        await fetchCounts(ids);
      }
    };
    fetchTransactionsAndCounts();
  }, [ids, activeTab,page,pageSize,searchQuery]);


  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    //timeZoneName: 'short'
  };

  const handleMenuClick = (event, row) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleApproveClick = (id) => {
    setSelectedId(id);
    setIsApproving(true);
    setOpen(true);
  };

  const handleRejectClick = (id) => {
    setSelectedId(id);
    setIsApproving(false);
    setOpen(true);
  };

  const handleConfirm = async () => {
    if (isConfirming) return;
  
    setIsConfirming(true);
    handleClose();
      let status = isApproving ? 'Approved' : 'Rejected';
      updateTrans(selectedId, status, ids).then(() => {
        toast.success('Transaction updated successfully', {
                 autoClose: 2000
             });
      
        fetchTransactions(ids, activeTab, page, pageSize, searchQuery);
       fetchCounts(ids);
      }).catch((error) => {
        console.log(error.message);
        let errorMessage = `failed to update transcation`;
        try {
          const errorDetail = error.message;
          if (errorDetail) {
            errorMessage = errorDetail;
          }
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
        }
        toast.error(errorMessage, { autoClose: 2000 });
    
      })
      setIsConfirming(false);
    //   console.log('Response received:', response);
    
    //   if (response.ok) {
    //     console.log('Processing response...');
    //     const data = await response.json();
    //     console.log('Data processed:', data);
    //     toast.success('Transaction updated successfully', {
    //       autoClose: 2000
    //     });
    
    //     console.log('Fetching transactions...');
    //     await fetchTransactions(ids, activeTab, page, pageSize, searchQuery);
    //     console.log('Fetching counts...');
    //     await fetchCounts(ids);
    //   } else {
    //     const errorData = await response.json();
    //     console.error('API Error:', errorData.detail);
    //     toast.error(errorData.detail || 'Failed to update transaction', {
    //       autoClose: 2000
    //     });
    //   }
    // } catch (error) {
    //   console.error('Error while updating transaction:', error);
    //   toast.error('Network error or server unreachable. ' + (error.message || 'Failed to update transaction'), {
    //     autoClose: 2000
    //   });
    // } finally {
    //   setIsConfirming(false);
    // }
    
  };

  

// const updateTransactions = async (id, status) => {
//     try {
//         // Directly return the promise from updateTrans to be handled by .then() in handleConfirm
//         return updateTrans(id, status); // Assuming updateTrans returns a promise that resolves with { ok: boolean }
//     } catch (error) {
//         console.error('Error while initiating update transaction:', error);
//         throw new Error('Failed to initiate transaction update'); // Re-throw to be caught by .then() chain
//     }
// };

const formatCurrency = (value, exchange) => {
  console.log(value, exchange)
  // Default currency symbol
  let currencySymbol = '';

  // Check if exchange is defined and set the currency symbol accordingly
  if (exchange === "NASDAQ" || exchange === "CRYPTO") {
    currencySymbol = '$';
  } else if (exchange === 'NSE'||exchange === "MCX") {
    currencySymbol = '₹';
  }

  // Return formatted currency with the determined symbol
  return `${currencySymbol} ${value ? value.toFixed(2) :0}`;
};

const getColumns = () => {
  const baseColumns = [
    { field: 'id', headerName: 'ID', flex: 1, sortable: true, disableColumnMenu: false },
    { field: 'amount', headerName: 'Amount', flex: 1, sortable: false, disableColumnMenu: true,
      renderCell: (params) => formatAndMultiplyValueAssetItem(params.value || 0, params.row && params.row.exchange),
     },
    { field: 'user_id', headerName: 'User ID', flex: 1, sortable: false, disableColumnMenu: true },
    { field: 'currency', headerName: 'Currency', flex: 1, sortable: false, disableColumnMenu: true },
    { field: 'exchange', headerName: 'Exchange', flex: 1, sortable: false, disableColumnMenu: true },
    {
      field: 'is_deposit',
      headerName: 'Transaction Type',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.is_deposit ? 'Deposit' : 'Withdraw';
      }
    }
  ];

  if (activeTab === 'Pending') {
    baseColumns.push(
      {
        field: 'status',
        headerName: 'Action',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <>
            <IconButton onClick={() => handleApproveClick(params.row.id)}>
              <Tooltip title="Approve" placement="top">
                <CheckIcon style={{ color: '#95de64' }} />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => handleRejectClick(params.row.id)}>
              <Tooltip title="Reject" placement="top">
                <ClearIcon style={{ color: '#ff7875' }} />
              </Tooltip>
            </IconButton>
          </>
        )
      }
    );
  } else {
    // Add the 'Updated By' column before 'Transaction Date' if activeTab is not 'Pending'
    baseColumns.splice(6, 0, {
      field: 'updated_by',
      headerName: 'Updated By',
      flex: 1,
      sortable: false,
      disableColumnMenu: true
    });
  }

  // Add 'Transaction Date' column
  baseColumns.push({
    field: 'created_at',
    headerName: 'Transaction Date',
    width: 270,
    sortable: true,
    disableColumnMenu: false,
    renderCell: (params) => {
      return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(params.value));
    }
  });

  return baseColumns;
};


  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab
              label={
                <span>
                  Pending{' '}
                  <Badge badgeContent={apiCounts?.Pending || 0} color="primary" style={{ marginLeft: '15px' }} max={99999} showZero />
                </span>
              }
              value="Pending"
            />
            <Tab
              label={
                <span>
                  Approved
                  <Badge badgeContent={apiCounts?.Approved || 0} color="primary" style={{ marginLeft: '15px' }} max={99999} showZero />
                </span>
              }
              value="Approved"
            />
            <Tab
              label={
                <span>
                  Rejected
                  <Badge badgeContent={apiCounts?.Rejected || 0} color="primary" style={{ marginLeft: '15px' }} max={99999} showZero />
                </span>
              }
              value="Rejected"
            />
          </Tabs>
        </Box>
      </Card>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mt={3}>
        <Grid>
          <TextField
            sx={{ ml: 1 }}
            placeholder="search "
            inputProps={{ 'aria-label': 'search with First Name' }}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: '20px' }} aria-label="search">
            <SearchIcon fontSize="medium" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid>
        <DataGrid
          rows={apiData}
          columns={getColumns()}
          sortModel={[
            {
              field: 'created_at',
              sort: 'desc'
            }
          ]}
          getRowId={(data) => data.id}
          autoHeight={false}
          sx={{ maxWidth: '97%', maxHeight: '90vh' }}
          hideFooterPagination
        hideFooter='true'
        />
         <Box display={"flex"} justifyContent={"flex-end"}>
     <TablePagination
   component="div"
   count={count}
   page={page}
   onPageChange={handleChange}
   rowsPerPage={pageSize}
   onRowsPerPageChange={handleChangeRowsPerPage}
 />            </Box>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{isApproving ? 'Confirm Approval' : 'Confirm Rejection'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isApproving ? 'Are you sure you want to approve this transaction?' : 'Are you sure you want to reject this transaction?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus disabled={isConfirming}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Grid>
  );
}

export default PaymentsList;
