import React, { useEffect,useState } from 'react';
import { Box, Grid, IconButton, TextField, Button, FormHelperText,Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createUser,getAllAgents } from '../../Services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
function SupportAddModal(props) {
const[agentId,setAgentId]=useState();
const[role,setRole]=useState();
// const[newUser,setNewUser]=useState({});
const [selectedUserId, setSelectedUserId] = useState('');
const[options,setOptions]=useState([]);
const[show,setShow]=useState(false);
const[userId,setUserId]=useState();
const[password,setPassword]=useState();
const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole)
  },[])

  const{modalHandleCallback}=props
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter the First Name'),
      lastName: Yup.string().required('Please Enter the Last Name'),
      email: Yup.string()
        .required('Please Enter the Email')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format')
      
    }),
    onSubmit: (values, { setSubmitting }) => {
      var newUser =  {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        user_role: 'SU',
        oversee_user:agentId
      }
      setIsLoading(true);
      createUser(newUser).then((response)=>{
        toast.success('Support User created successfully', { autoClose: 2000 });
        setShow(true);
        setUserId(response.user_id);
        setPassword(response.password);
      }).catch((error)=>{
        let errorMessage = 'Failed to create Support User';
        try{
          const errorDetail = JSON.parse(error.message);
          if (errorDetail && errorDetail.detail) {
              errorMessage = errorDetail.detail;
              // console.log(errorMessage);
          }
        } catch (parseError) {
             console.error('Error parsing JSON response:', parseError);
           }finally{
            setSubmitting(false);
            setIsLoading(false);
           }
           toast.error(errorMessage, { autoClose: 2000 });
           setSubmitting(false);
           setIsLoading(false);
      })
    }
});
  const handleSelection = (event, value) => {
    setSelectedUserId(value ? value.id : '');
  };

  return (
    <>
    <form onSubmit={validation.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            type="text"
            label="First Name"
            variant="outlined"
            fullWidth
            name="firstName"
            value={validation.values.firstName || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
          />
          {validation.errors.firstName && validation.touched.firstName ? (
            <FormHelperText error>{validation.errors.firstName}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            name="lastName"
            value={validation.values.lastName || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
          />
          {validation.errors.lastName && validation.touched.lastName ? (
            <FormHelperText error>{validation.errors.lastName}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            label="Email"
            varian="outlined"
            fullWidth
            name="email"
            value={validation.values.email || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.email && validation.touched.email ? true : false}
          />
          {validation.errors.email && validation.touched.email ? <FormHelperText error>{validation.errors.email}</FormHelperText> : null}
        </Grid>
        {/* <Grid item xs={12} sm={6} mt={2}>
          <TextField
          type="number"
            label="Balance"
            variant="outlined"
            fullWidth
            sx={{
                '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield'
                }
              }}
            name="balance"
            value={validation.values.balance}
            onBlur={() => {
              validation.values.balance != 0
                ? validation.setTouched({
                    ...validation.touched,
                    balance: false
                  })
                : validation.setTouched({
                    ...validation.touched,
                    balance: true
                  });
            }}
            onChange={(e, v) => validation.setFieldValue('balance', e?.target?.value)}
          />
           {validation.errors.balance && validation.touched.balance ? (
            <FormHelperText error>{validation.errors.balance}</FormHelperText>
          ) : null}
        </Grid> */}
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Button type="submit" variant="outlined" color="success">
          Add Support User
        </Button>
      </Grid>
      </form>
      <ToastContainer/>
    </>
  );
}

export default SupportAddModal;
