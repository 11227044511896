const env=process.env.REACT_APP_API_URL;
export const getUserTokens = async (category,userId, searchTerm, page, pageSize) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      const response = await fetch(`${env}/userzinstruments/${category}?userId=${userId}&search_keyword=${searchTerm}&page=${page}&page_size=${pageSize}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getZTokensByUser = async (user_id, type) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/user-token-mapping/${user_id}/?type=${type}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const mappingUserToken = async (NseData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/user-token-mapping/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(NseData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const globalmarginUpdate = async (data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/global-margin-update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };


  export const mappingAllTokens = async (Data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/add-userfinancial-instruments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(Data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const getAllTokenConfig = async (user_id,exchange) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/check-all-userinstruments?user_id=${user_id}&exchange=${exchange}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };


  export const verifyOtp = async (email,otp) => {
    try {
      const response = await fetch(`${env}/verify-otp/${email}?otp=${otp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const verifyFirstOtp = async (email,otp) => {
    try {
      const response = await fetch(`${env}/first-verify-otp/${email}?otp=${otp}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };


  export const getOverview = async (oversee_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/get_financial_overview/${oversee_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };


  export const getClosing = async (oversee_id, segment, search_term = '', page_size = 10, page_number = 1) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      const queryParams = new URLSearchParams({
        segment,
        search_term,
        page_size: page_size.toString(),
        page_number: page_number.toString()
      });
  
      const response = await fetch(`${env}/get_instruments_closing/${oversee_id}?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status === 404) {
        return { data: [], total_count: 0 };
      } else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const jsonResponse = await response.json();
      return { data: jsonResponse.data, total_count: jsonResponse.total_count };
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };
  