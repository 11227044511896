import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { createHoliday } from '../../Services/HolidayService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddHoliday(props) {
    const [selectedMarket, setSelectedMarket] = useState([]);
  const [date, setDate] = useState(null);
  const [isFullDay, setIsFullDay] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeError, setTimeError] = useState('');
  const [type, setType] = useState('Holiday');
  const [zone, setZone] = useState('IST');
  const[reason,setReason]=useState();
  const[loading,setLoading]=useState(false)
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
  };
  const handleZoneChange = (event) => {
    setZone(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setDate(newValue);
  };

  const handleTimeChange = (newValue, type) => {
    setTimeError('');

    if (type === 'start') {
      setStartTime(newValue);
      if (endTime && newValue && dayjs(endTime).isSameOrBefore(dayjs(newValue))) {
        setEndTime(null);
      }
    } else if (type === 'end') {
      if (startTime && newValue && dayjs(newValue).isAfter(dayjs(startTime))) {
        setEndTime(newValue);
      } else {
        setTimeError('End time must be greater than start time.');
      }
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    let formData = {
      timeZone:zone,
        market: selectedMarket,
        date: date ? date.format('YYYY-MM-DD') : '',
        reason:reason,
        fullday: isFullDay,
        type: type
      };

    if (!isFullDay) {
        formData = {
          ...formData,
          startTime: startTime ? startTime.format('HH:mm:ss') : '',
          endTime: endTime ? endTime.format('HH:mm:ss') : '',
        };
      }
        else {
          formData = {
          ...formData,
          startTime : '00:00:01',
          endTime : '23:59:59',
          };
      }
      

      createHoliday(formData)
      .then((response) => {
        toast.success('created successfully', {
          autoClose: 2000
        });
        setEndTime(null);
        setStartTime(null);
        setDate(null);
        setSelectedMarket([]);
        setTimeout(() => {
          props.modalHandleCallback();
        }, 1000);
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Failed to create', {
          autoClose: 2000
        });
      }).finally(()=>{
        setLoading(false);
      })
  }
  return (
    <>
      <Grid container spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="market-select-label">TimeZone</InputLabel>
              <Select
                labelId="market-select-label"
                id="market-select"
                value={zone}
                label="TimeZone"
                onChange={handleZoneChange}
                // sx={{ width: 250 }}
              >
                <MenuItem value={'IST'}>IST</MenuItem>
                <MenuItem value={'ET'}>ET</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="market-select-label">Market/App</InputLabel>
              <Select
                labelId="market-select-label"
                id="market-select"
                multiple
                value={selectedMarket}
                label="Market/App"
                onChange={handleMarketChange}
                // sx={{ width: 250 }}
              >
                <MenuItem value={'NSE'}>NSE</MenuItem>
                <MenuItem value={'CRYPTO'}>CRYPTO</MenuItem>
                <MenuItem value={'NASDAQ'}>NASDAQ</MenuItem>
                <MenuItem value={'MCX'}>MCX</MenuItem>
                <MenuItem value={'APPLICATION'}>APPLICATION</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker label="Date" value={date} onChange={handleStartDateChange} renderInput={(params) => <TextField {...params} />} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select labelId="type-select-label" id="type-select" value={type} label="Type" onChange={handleTypeChange}>
                <MenuItem value="Holiday">Holiday</MenuItem>
                <MenuItem value="Maintenance">Maintenance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <TextField id="outlined-basic" value={reason}  label="Comments" variant="outlined" onChange={(e)=>setReason(e.target.value)} required/>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isFullDay} onChange={(e) => setIsFullDay(e.target.checked)} />}
                label="Full Day"
              />
            </FormGroup>
          </Grid>
          {!isFullDay && (
            <>
              <Grid item xs={6}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => handleTimeChange(newValue, 'start')}
                  renderInput={(params) => <TextField {...params} />}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={(newValue) => handleTimeChange(newValue, 'end')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!timeError}
                      helperText={timeError}
                      disabled={!startTime} // Disable until a start time is selected
                    />
                  )}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock
                  }}
                />
                {timeError && <Typography color="error">{timeError}</Typography>}
              </Grid>
            </>
          )}
        </LocalizationProvider>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Button type="submit" variant="outlined" color="success" onClick={handleSubmit}>
        {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </Grid>
      <ToastContainer/>
    </>
  );
}

export default AddHoliday;
