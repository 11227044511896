import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Button, FormHelperText, Autocomplete, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postAgentBalance, wallets, getWallets } from '../../Services/UserService';
import { jwtDecode } from 'jwt-decode';

function WalletAgentModal(props) {
  const { data, modalHandleCallback } = props;
  const [amount, setAmount] = useState('');
  const [agentId, setAgentId] = useState();
  const [walletType, setWalletType] = useState('');
  const walletTypes = ['NSE', 'CRYPTO', 'NASDAQ', 'MCX'];

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { amount: '' },
    validationSchema: Yup.object({
      amount: Yup.number()
        .positive('Please Enter a valid Amount')
        .moreThan(0, 'Amount must be greater than zero')
        .required('Please Enter an Amount')
    }),
  });

  const handleAddOrWithdraw = (add) => {
    const balance = {
      user_id: data.user_id,
      exchange: walletType,
      balance: add ? amount : -amount,
      oversee_id: agentId
    };
    postAgentBalance(balance)
      .then(() => {
        toast.success(`Balance ${add ? 'added' : 'withdrawn'} successfully`, { autoClose: 2000 });
        validation.resetForm({ values: { amount: '' } });
        setAmount('');
        setTimeout(() => modalHandleCallback(), 1000);
      })
      .catch((error) => {
        let errorMessage = `Failed to ${add ? 'add' : 'withdraw'} balance`;
        try {
          const errorDetail = JSON.parse(error.message);
          if (errorDetail && errorDetail.detail) {
            errorMessage = errorDetail.detail;
          }
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
        }
        toast.error(errorMessage, { autoClose: 2000 });
      });
  };

  return (
    <>
      <form onSubmit={validation.handleSubmit}>
        <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" mt={4}>
          <Box>
            <Autocomplete
              disablePortal
              id="wallet-type-autocomplete"
              options={walletTypes}
              sx={{ width: 200 }}
              value={walletType}
              onChange={(event, newValue) => setWalletType(newValue)}
              renderInput={(params) => <TextField {...params} label="Wallet Type" />}
            />
          </Box>
          <Box>
            {walletType && (
              <Grid container spacing={2} justifyContent="space-around">
                <Grid item>
                  <TextField
                    type="number"
                    label="Amount"
                    variant="outlined"
                    name="amount"
                    value={validation.values.amount}
                    onChange={(e) => (validation.setFieldValue('amount', e.target.value), setAmount(e.target.value))}
                    onBlur={validation.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    helperText={validation.touched.amount ? validation.errors.amount : ''}
                    error={validation.touched.amount && Boolean(validation.errors.amount)}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
        <Grid container spacing={2} justifyContent="space-around" mt={5}>
          <Button variant="outlined" color="error" onClick={() => handleAddOrWithdraw(false)} disabled={!validation.isValid || !validation.dirty || !amount}>
            Withdraw
          </Button>
          <Button variant="outlined" color="success" onClick={() => handleAddOrWithdraw(true)} disabled={!validation.isValid || !validation.dirty || !amount}>
            Deposit
          </Button>
        </Grid>
      </form>
      <ToastContainer />
    </>
  );
}

export default WalletAgentModal;
