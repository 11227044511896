import React,{useState,useEffect} from 'react'
import { Box, Grid,IconButton,TextField,Button, Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Card, Tabs, Tab, Badge,
  DialogTitle, } from '@mui/material';
  import { toast, ToastContainer } from 'react-toastify';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import { getAllAgents,updateUser,getAllAgentCounts,deleteUserById, updateUserByOversee } from '../../Services/UserService'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAgentModal from './AddAgentModel';
import EditAgentModal from './EditAgentModal';
import WalletAgentModal from './WalletAgentModal';
import { jwtDecode } from "jwt-decode";
import TablePagination from '@mui/material/TablePagination';
import WalletListModal from './WalletListModal';
function AgentList() {
  const [searchQuery, setSearchQuery] = useState();
  const [data, setData] = useState([]);
  const [apidata, setApiData] = useState([]);
  const [apiCounts,setApiCount]=useState();
  const [rowData,setRowData]=useState();
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [Dis, setDis] = useState(false);
  const [Act, setAct] = useState(false);
  const [Del, setDel] = useState(false);
  const[ids,setId]=useState();
  const [openWallet, setOpenWallet] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const[page,setPage]=useState(0);
  const [pageSize,setPageSize]=useState(10)
  const[count,setCount]=useState(0);
  const [walletList, setWalletList] = useState(false);
  const [loading,setloading]=useState(false);
  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  },[])

  const handleChangeTab = (event, newValue) => {
    setApiData([])
    setActiveTab(newValue);
  };
  const fetchAgents = async (activeTab, ids, searchQuery, page , pageSize) => {
    try {
      setloading(true);
      let userData;
      if (activeTab === "active") {
        userData = await getAllAgents("true", ids, page+1, pageSize, searchQuery);
      } else if (activeTab === "disable") {
        userData = await getAllAgents("false", ids, page+1, pageSize, searchQuery);
      }
setApiData(userData?.rows);
setCount(userData?.count);
    } catch (error) {
      setloading(false);
      console.error('Error fetching Agents: ', error);
    }finally{
      setloading(false);
    }
  };
  const fetchCounts = async (user_id) => {
    try {
      const data = await getAllAgentCounts(user_id);
      setApiCount(data);
    } catch (error) {
      console.error('Error fetching Counts: ', error);
    }
  };
  const handleDeleteUser = async (userId) => {
    try {
      await deleteUserById(userId);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  useEffect(() => {
    if (!ids) return; // Add this check
    fetchAgents(activeTab, ids, searchQuery, page, pageSize);
    fetchCounts(ids);
  }, [activeTab, ids,searchQuery,page,pageSize]);
  
  const activeColumns = [
    {
      field: 'user_id',
      headerName: 'User Id',
      flex:1,
     
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex:1,
     
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex:1,
    
    },
    {
      field: 'email',
      headerName: 'Email',
      flex:1,
      
    },
    {
      field: 'oversee_user',
      headerName: 'Oversee ID',
      flex: 1,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      flex:1,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline',fontSize:'12px' }} onClick={() => (setWalletList(true),setData(params.row))}>
          view
        </div>
      )
      
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
    disableColumnMenu: true,
      flex:1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDisable(params.row)}>
          <Tooltip title="Disable" placement='top'>
          <VisibilityOffIcon style={{color:'#ff7875'}}/>
          </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleEdit(params.row)}>
          <Tooltip title="Edit" placement='top'>
            <EditIcon style={{color:'#ffd666'}}/>
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleWallet(params.row)}>
          <Tooltip title="Update Wallet" placement='top'>
            <AccountBalanceWalletIcon style={{color:'#95de64'}}/>
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];
  const disableColumns = [
    {
      field: 'user_id',
      headerName: 'User Id',
      flex:1,
     
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex:1,
     
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex:1,
    
    },
    {
      field: 'email',
      headerName: 'Email',
      flex:1,
      
    },
    {
      field: 'oversee_user',
      headerName: 'Oversee ID',
      flex: 1,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      flex:1,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline',fontSize:'12px' }} onClick={() => (setWalletList(true),setData(params.row))}>
          view
        </div>
      )
      
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
    disableColumnMenu: true,
      flex:1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleActivate(params.row)}>
          <Tooltip title="Activate" placement='top'>
          <VisibilityIcon style={{color:'#95de64'}}/>
          </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row)}>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon style={{ color: '#ff7875' }} />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const Disable = (id) => {
    updateUserByOversee( id,"false")
      .then((response) => {
        setDis(false);
        fetchAgents(activeTab, ids, searchQuery, page, pageSize);
        fetchCounts(ids);
        toast.success("User disabled successfully",{
          autoClose: 2000,
        });
       
      })
      .catch((error) => {
        toast.error("Failed to disable User",{
          autoClose: 2000,
        });
      });
  };
  const Activate = (id) => {
    updateUserByOversee( id,"true")
      .then((response) => {
        setAct(false);
        fetchAgents(activeTab, ids, searchQuery, page, pageSize);
        fetchCounts(ids);
        toast.success("User activated successfully",{
          autoClose: 2000,
        });
       
      })
      .catch((error) => {
        toast.error("Failed to activate User",{
          autoClose: 2000,
        });
      });
  };
  const Delete = (id) => {
    handleDeleteUser(id)
    .then((response) => {
      setDel(false);
      fetchAgents(activeTab, ids, searchQuery, page, pageSize);
      fetchCounts(ids);
      toast.success("Agent Deleted successfully",{
        autoClose: 2000,
      });
     
    })
    .catch((error) => {
      toast.error("Failed to delete Agent",{
        autoClose: 2000,
      });
    });
  };
  const handleActivate=(row)=>{
    setAct(true);
    setRowData(row);
  }
  const handleDisable=(row)=>{
    setDis(true);
    setRowData(row);
  }
  const handleDelete =(row)=>{
    setDel(true);
    setRowData(row);
  }
  const handleEdit=(data)=>{
    setData(data);
    setOpenEdit(true);
  }

  const handleModalClose = () => {
    fetchAgents(activeTab, ids, searchQuery, page, pageSize);
    fetchCounts(ids);
    setOpenNew(false);
    setOpenWallet(false);
    setOpenEdit(false);
  };
const handleWallet=(data)=>{
  setData(data);
  setOpenWallet(true);
}
const handleChange = (event, newPage) => {
  setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
  setPageSize(parseInt(event.target.value, 10));
  setPage(0);
};
  return (
  <Grid container sx={{display:'flex',flexDirection:'column'}}>
     <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label={
            <span>
              Active <Badge badgeContent={apiCounts?.active_count||0} color="primary" style={{marginLeft:"15px"}} max={99999} showZero/>
            </span>
          } value="active" />
          <Tab label={
            <span>
              Disable<Badge badgeContent={apiCounts?.inactive_count ||0} color="primary" style={{marginLeft:"15px"}} max={99999} showZero/>
            </span>
          } value="disable" />
         
        </Tabs>
      </Box>
      
    </Card>
    <Grid sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} mt={3}>
    <Grid>
    <TextField
        sx={{ ml: 1, flex: 1 }}
        placeholder="search"
        inputProps={{ 'aria-label': 'search with First Name' }}
        onChange={(e)=>setSearchQuery(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '20px' }} aria-label="search">
        <SearchIcon fontSize='medium'/>
      </IconButton>
    </Grid>
    <Grid>
      <Button variant="outlined" color="success" onClick={()=>setOpenNew(true)}>Add New Agent</Button>
    </Grid>
    </Grid>
    {activeTab === "active" && (
      <>
    <DataGrid rows={apidata} columns={activeColumns} 
        getRowId={(data) => data.user_id} 
        autoHeight={apidata.length === 0}
        sx={{ maxWidth: '97%', maxHeight: apidata.length > 0 ? '90vh' : 'auto', minHeight: apidata.length ? '400px' : '100px' }}
        hideFooterPagination
        hideFooter='true'
        loading={loading}
         />
         <Box display={"flex"} justifyContent={"flex-end"}>
         <TablePagination
       component="div"
       count={count}
       page={page}
       onPageChange={handleChange}
       rowsPerPage={pageSize}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />            </Box>
     </>
    )}
    {activeTab === "disable" && (
      <>
    <DataGrid rows={apidata} columns={disableColumns} 
        getRowId={(data) => data.user_id} 
        autoHeight={apidata.length === 0}
        sx={{ maxWidth: '97%', maxHeight: apidata.length > 0 ? '90vh' : 'auto', minHeight: apidata.length ? '400px' : '100px' }}
        hideFooterPagination
        hideFooter='true'
        loading={loading}
         />
         <Box display={"flex"} justifyContent={"flex-end"}>
         <TablePagination
       component="div"
       count={count}
       page={page}
       onPageChange={handleChange}
       rowsPerPage={pageSize}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />            </Box>
     </>
    )}
    <Dialog
          open={openNew}
          onClose={() => handleModalClose()}
          fullWidth="sm"
          maxWidth="sm"
        >
          <DialogTitle>Add New Agent</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleModalClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <AddAgentModal modalHandleCallback={handleModalClose} />
          </DialogContent>
        </Dialog>
        <Dialog open={openEdit} onClose={() => setOpenEdit(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Update Agent</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenEdit(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <EditAgentModal openEdit={openEdit} data={data} modalHandleCallback={handleModalClose} />
        </DialogContent>
      </Dialog>
     <Dialog open={openWallet} onClose={() => setOpenWallet(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Update Agent Wallet</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenWallet(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <WalletAgentModal  modalHandleCallback={handleModalClose} data={data}/>
        </DialogContent>
      </Dialog>
      <Dialog open={walletList} onClose={() => setWalletList(false)} fullWidth="lg" maxWidth="sm">
        <DialogTitle>Wallets balance</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setWalletList(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <WalletListModal modalHandleCallback={handleModalClose} data={data}/>
        </DialogContent>
      </Dialog>
      <Dialog open={Del} onClose={() => setDel(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Delete {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setDel(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Delete(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={Dis} onClose={() => setDis(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Disable {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setDis(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Disable(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={Act} onClose={() => setAct(false)} fullWidth="sm" maxWidth="sm">
      <DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <p>Are you sure you want to Activate {rowData?.user_id}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setAct(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => Activate(rowData?.user_id)}
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
      <ToastContainer/>
  </Grid>
  )
}

export default AgentList