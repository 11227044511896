const env=process.env.REACT_APP_API_URL;


export const createHoliday = async (holidayData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/add-holiday/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(holidayData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error creating Holiday: ', error);
      throw error;
    }
  };

  export const getHolidays = async (type) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      // Construct the URL conditionally
      let url = `${env}/holidays/by-type/?type=${type}`;
      
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const deleteHoliday = async (Id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/holidays/${Id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      
      if (response.status === 204) {
        console.log(`User/Agent with ID ${Id} deleted.`);
      } else {
        console.error('Failed to delete.');
      }
    } catch (error) {
      console.error('Error deleting :', error);
      throw error;
    }
  };

  export const getHolidayCounts = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      // Construct the URL conditionally
      let url = `${env}/holidays/count-by-market/`;
      
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const updateHoliday = async (updatedData,id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/update-holiday/${id}/`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  export const getTimings = async (type) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      // Construct the URL conditionally
      let url = `${env}/timimngs`;
      
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching timings: ', error);
      throw error;
    }
  };

  export const updateTimings = async (holidayData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/timings/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(holidayData),
      });
  
      if (!response.ok) {
        const errorDetail = await response.text();
        throw new Error(`Network response was not ok: ${errorDetail}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating timings: ', error);
      throw error;
    }
  };