import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Tabs,
  Tab,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  FormControlLabel
} from '@mui/material';
import { getAllUsers } from '../../Services/UserService';
// import { mappingAllTokens,getAllTokenConfig } from '../../Services/ConfigService';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import { getUserTokens, mappingUserToken, getZTokensByUser, mappingAllTokens, getAllTokenConfig, globalmarginUpdate } from '../../Services/UserConfigService';
import { CircularProgress, Tooltip } from '@mui/material';
import DeselectIcon from '@mui/icons-material/Deselect';
const UserConfig = () => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiData, setApiData] = useState([]);
  const [zTokensData, setZTokensData] = useState([]);
  const [zTokensAgent, setZTokensAgent] = useState([]);
  const [selected, setSelected] = useState();
  const [options, setOptions] = useState();
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [names, setNames] = useState([]);
  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [transformedData, setTransformedData] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [role, setRole] = useState('');
  const [category, setCategory] = useState('NSE');
  const [open, setOpen] = useState(false);
  const [pendingCategory, setPendingCategory] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [filteredSelectedNames, setFilteredSelectedNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [margins, setMargins] = useState({});
  const [applyAll,setApplyAll]=useState();
  const[globalMargin,setGlobalMargin]=useState();
  const fetchZTokens = useCallback(async () => {
    if (selected) {
      try {
        setLoading1(true);
        const data = await getUserTokens(category, selected, searchTerm, page, pageSize);
        setZTokensData(data?.rows);
        setCount(data?.count);
      } catch (error) {
        console.error('Error fetching tokens: ', error);
      } finally {
        setLoading1(false);
      }
    }
  }, [selected, category, searchTerm, page, pageSize, selected]);

  const fetchZTokensUser = useCallback(async () => {
    if (selected) {
      try {
        setLoading2(true);
        const agentTokens = await getZTokensByUser(selected, category);
        setSelectedNames(
          agentTokens?.data?.map((item) => ({
            id: item.financial_instrument_id,
            name: item.trading_symbol,
            exchange: item.exchange,
            margin: item.margin
          }))
        );
      } catch (error) {
        console.error('Error fetching agent tokens: ', error);
      } finally {
        setLoading2(false);
      }
    }
  }, [selected, category]);

  const fetchUsers = async (user_id, keyword) => {
    try {
      const data = await await getAllUsers('true', user_id, 1, 10, keyword);
      setApiData(data?.rows);
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };
  useEffect(() => {
    if (agentId) {
      fetchUsers(agentId, searchQuery);
    }
  }, [agentId, searchQuery]);
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole);
  }, []);

  useEffect(() => {
    const lowercasedFilter = selectedSearchTerm?.toLowerCase();
    const filteredData = selectedNames.filter((item) => item?.name?.toLowerCase().includes(lowercasedFilter));
    setFilteredSelectedNames(filteredData);
  }, [selectedSearchTerm, selectedNames]);

  useEffect(() => {
    fetchZTokens().catch(console.error);
  }, [fetchZTokens]);

  useEffect(() => {
    fetchZTokensUser().catch(console.error);
  }, [fetchZTokensUser]);

  const handleChange = (newValue) => {
    setOpen(true);
    setSearchTerm('');
    setSelectedSearchTerm('');
    setPage(1);
    setPendingCategory(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setNames([]);
    setCategory(pendingCategory);
    handleClose();
  };
  useEffect(() => {
    setNames(
      zTokensData.map((token) => ({
        id: token.financial_instrument_id,
        name: token.trading_symbol,
        instrument_type: token.instrument_type,
        exchange: token.exchange
      }))
    );
  }, [zTokensData]);

  useEffect(() => {
    setSelectedNames(transformedData?.filter((tItem) => names.some((nItem) => nItem.id === tItem.id)));
  }, [transformedData]);

  useEffect(() => {
    setOptions(
      apiData.map((user) => ({
        label: user.email + ' (' + user.user_id + ')',
        id: user.user_id
      }))
    );
  }, [apiData]);

  const handleSelect = (event, value) => {
    setAutocompleteValue(value);
    setSelected(value ? value.id : '');
    if (!value || (value && value.id !== selected)) {
      setSelectedNames([]);
    }
  };

  const handleToggle = (value) => {
    const currentIndex = selectedNames.findIndex((name) => name.id === value.id);
    let newSelectedNames = [...selectedNames];
    if (currentIndex === -1) {
      newSelectedNames.push({ ...value, margin: 1 }); // Default margin as 1
    } else {
      newSelectedNames.splice(currentIndex, 1);
    }
    setSelectedNames(newSelectedNames);
  };

  const handleMarginChange = (id, newMargin) => {
    let marginAsFloat = parseFloat(newMargin);
    marginAsFloat = isNaN(marginAsFloat) ? 1 : marginAsFloat; // Use default margin of 1 if conversion fails

    setSelectedNames((prev) => prev.map((item) => (item.id === id ? { ...item, margin: marginAsFloat } : item)));
  };

  const handleSave = () => {
    setIsLoading(true);
    const stock = {
      user_id: selected,
      exchange: category === 'NFO-FUT' ? 'NSE' : category,
      segment: category,
      stocks: selectedNames
    };
    mappingUserToken(stock)
      .then((response) => {
        toast.success('Mapping created successfully', {
          autoClose: 2000
        });
      })
      .catch((error) => {
        toast.error('Failed to create Mapping', {
          autoClose: 2000
        });
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
  };

  const handleDeSelect = () => {
    setSelectedNames([]);
  };
  useEffect(() => {
    const initialMargins = {};
    filteredSelectedNames.forEach((name) => {
      initialMargins[name.id] = name.margin || 1; // Default to 1 if not provided
    });
    setMargins(initialMargins);
  }, [filteredSelectedNames]);

  const handleMargin = (id, value) => {
    if (value === '' || value === '0.' || (/^0\.\d{1,2}$/.test(value) && parseFloat(value) >= 0.01 && parseFloat(value) <= 1)) {
      setMargins((prev) => ({ ...prev, [id]: value }));
    } else if (/^\d(\.\d{1,2})?$/.test(value) && parseFloat(value) <= 1) {
      setMargins((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleMarginBlur = (id) => {
    const currentValue = margins[id];
    const numericValue = parseFloat(currentValue);
    if (currentValue === '' || isNaN(numericValue) || numericValue < 0.01 || numericValue > 1) {
      const updatedValue = 1; // Default to 1 if the field is out of range or left empty
      setMargins((prev) => ({ ...prev, [id]: updatedValue }));
      handleMarginChange(id, updatedValue);
    } else if (currentValue !== numericValue.toFixed(2)) {
      // Ensure the precision is up to two decimal places
      const fixedValue = numericValue.toFixed(2);
      setMargins((prev) => ({ ...prev, [id]: fixedValue }));
      handleMarginChange(id, fixedValue);
    } else {
      handleMarginChange(id, numericValue);
    }
  };

  const handleGlobalMarginChange = (value) => {
    if (value === '' || value === '0.' || (/^0\.\d{1,2}$/.test(value) && parseFloat(value) >= 0.01 && parseFloat(value) <= 1)) {
      setGlobalMargin(value);
    } else if (/^\d(\.\d{1,2})?$/.test(value) && parseFloat(value) <= 1) {
      setGlobalMargin(value);
    }
  };
  
  const handleGlobalMarginBlur = () => {
    const numericValue = parseFloat(globalMargin);
    if (globalMargin === '' || isNaN(numericValue) || numericValue < 0.01 || numericValue > 1) {
      const updatedValue = 1;
      setGlobalMargin(updatedValue);
    } else {
      if (numericValue === 1) {
        setGlobalMargin('1');  
      } else {
        const fixedValue = numericValue.toFixed(2);
        setGlobalMargin(fixedValue);
      }
    }
  };
  

  const handleSaveGlobalMargin=()=>{
const Globaldata={
  user_id: selected,
  exchange: category === 'NFO-FUT' ? 'NSE' : category,
  segment: category,
  global_margin: globalMargin
}
setIsLoading2(true)
globalmarginUpdate(Globaldata).then((response) => {
  toast.success('Mapping created successfully with margin', {
    autoClose: 2000
  });
  fetchZTokensUser();
  fetchZTokens();
})
.catch((error) => {
  toast.error('Failed to create Mapping with margin', {
    autoClose: 2000
  });
  setIsLoading2(false);
})
.finally(() => {
  setIsLoading2(false);
});
  }
  return (
    <Grid>
      <Box display="flex" flexDirection="row" justifyContent="space-between" pb={2}>
        <Box>
          <Autocomplete
            disablePortal
            value={autocompleteValue}
            id="combo-box-demo"
            options={options}
            sx={{ width: 350 }}
            onChange={handleSelect}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User ID"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.75rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Button variant="outlined" color="success" onClick={() => handleSave()} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Save Config'}
          </Button>
          <Typography>* Save the config before switching to another tab</Typography>
        </Box>
      </Box>

      <Tabs value={category} aria-label="category">
        <Tab label="NSE" value="NSE" onClick={() => handleChange('NSE')} />
        <Tab label="FUTURES" value="NFO-FUT" onClick={() => handleChange('NFO-FUT')} />
        <Tab label="CRYPTO" value="CRYPTO" onClick={() => handleChange('CRYPTO')} />
        <Tab label="NASDAQ" value="NASDAQ" onClick={() => handleChange('NASDAQ')} />
        <Tab label="MCX" value="MCX" onClick={() => handleChange('MCX')} />
      </Tabs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3}>
            <Box display="flex" flexDirection="row" justifyContent="space-around">
              <Typography variant="h4" mt={3}>
                Available
              </Typography>
              {/* <Box mt={2} display="flex" flexDirection="row" justifyContent="space-around">
                <IconButton onClick={() => handleDeSelect()} id="deSelect">
                  <Tooltip title="deSelect all">
                    <DeselectIcon />
                  </Tooltip>
                </IconButton>
              </Box> */}
               <Box mt={2}>
      <FormControlLabel
        control={<Checkbox checked={applyAll} onChange={(e) => setApplyAll(e.target.checked)} />}
        label="Select all with margin"
      />
      </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row',}}>
            <TextField
              label="Search with Symbol"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(1);
              }}
              sx={{ m: 2, width: '200px' }}
            />
             {applyAll && (
        <Box sx={{ display: 'flex', alignItems: 'center'}} ml={2}  >
          <TextField
            label="Global margin"
            variant="outlined"
            value={globalMargin}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => handleGlobalMarginChange(e.target.value)}
            onBlur={() => handleGlobalMarginBlur()}
            type="text"
            InputProps={{
              inputProps: {
                pattern: '0\\.\\d{2}|1\\.00|1'
              }
            }}
            sx={{ width: '150px' }}
          />
          <Button
            variant="outlined"
            color="success"
            onClick={()=>handleSaveGlobalMargin()}
            sx={{ ml: 2 }}
            disabled={isLoading2}
          >
           {isLoading2 ? <CircularProgress size={24} /> : 'Apply'}
          </Button>
          </Box>
      )}
            </Box>
            {loading1 ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <List sx={{ height: 430, overflow: 'auto' }}>
                  {names.length > 0 ? (
                    names.map((name) => (
                      <ListItem key={name.id} dense button onClick={() => handleToggle(name)}>
                        <Checkbox
                          edge="start"
                          checked={selectedNames.some((selected) => selected.id === name.id)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={name.name} />
                      </ListItem>
                    ))
                  ) : (
                    <Box mx={'auto'} my={'auto'}>
                      <Typography variant="subtitle1" color="textSecondary" align="center">
                        Set AgentConfig to get available scripts
                      </Typography>
                    </Box>
                  )}
                </List>
                <Box display="flex" justifyContent="center">
                  {/* Pagination component for names list */}
                  <Pagination count={Math.ceil(count / pageSize)} page={page} onChange={(event, value) => setPage(value)} color="primary" />
                </Box>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
  <Paper elevation={3}>
    <Box display="flex" justifyContent="space-around">
      <Box mt={4}>
      <Typography variant="h4" >
        Selected
      </Typography>
      </Box>
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
      <TextField
        label="Search with Symbol"
        variant="outlined"
        value={selectedSearchTerm}
        onChange={(e) => setSelectedSearchTerm(e.target.value)}
        sx={{ width: '200px' }}
      />
    </Box>

    <Box textAlign={'right'} mr={2} mb={1}>
      <Typography>*Margin value should be between 0.01 to 1</Typography>
    </Box>

    {loading2 ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    ) : (
      <List sx={{ height: 425, overflow: 'auto', mx: 2 }}>
        {filteredSelectedNames.slice(0, 20).map((name) => (
          <ListItem
            key={name.id}
            secondaryAction={
              <TextField
                label="Margin"
                variant="outlined"
                type="text"
                onWheel={(e) => e.target.blur()}
                InputProps={{
                  inputProps: {
                    pattern: '0\\.\\d{2}|1\\.00|1'
                  }
                }}
                value={margins[name.id]}
                onChange={(e) => handleMargin(name.id, e.target.value)}
                onBlur={() => handleMarginBlur(name.id)}
                sx={{ width: '100px' }}
              />
            }
          >
            <ListItemText primary={name.name} />
          </ListItem>
        ))}
      </List>
    )}
  </Paper>
</Grid>


      </Grid>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Save the config before changing the tab, If you made any changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserConfig;
