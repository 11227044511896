import { getWalletList, getAgentBrokerages } from '../../Services/WalletConfigService';
import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { formatValueIndian } from '../../utils/Formatvalue';

function WalletListModal(props) {
  const { data, modalHandleCallback } = props;
  const [apidata, setApiData] = useState();
  const [brokerageData, setBrokerageData] = useState(null);
  useEffect(() => {
    fetchWallets(data.user_id);
    fetchAgentBrokerage(data.oversee_user, data.user_id);
  }, []);
  const fetchAgentBrokerage = async (oversee_user, user_id) => {
    try {
      const dataRes = await getAgentBrokerages(oversee_user, user_id);
      setBrokerageData(dataRes);
    } catch (error) {
      console.error('Error fetching borkerage data: ', error);
    }
  };
  const fetchWallets = async (user_id) => {
    try {
      const datas = await getWalletList(user_id);
      setApiData(datas?.wallets);
    } catch (error) {
      console.error('Error fetching wallets data: ', error);
    }
  };
  return (
    <Paper elevation={3}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Exchange</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Sub Agent Brokerage</TableCell>
            <TableCell>Agent Brokerage</TableCell>
            <TableCell>Wallet Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apidata?.map((row) => (
            <TableRow key={row.exchange}>
              <TableCell>{row.exchange}</TableCell>
              <TableCell>
                {row.exchange == 'NSE' || row.exchange == 'MCX' ? '₹' : '$'}
                {formatValueIndian(row.balance)}
              </TableCell>
              <TableCell>
                {row.exchange == 'NSE' || row.exchange == 'MCX' ? '₹' : '$'}
                {formatValueIndian(brokerageData?brokerageData?.brokerageThroughSubagent[row.exchange]?.total_subagent_brokerage?brokerageData?.brokerageThroughSubagent[row.exchange]?.total_subagent_brokerage:0:0)}
              </TableCell>
              <TableCell>
                {row.exchange == 'NSE' || row.exchange == 'MCX' ? '₹' : '$'}
                 {formatValueIndian(brokerageData?brokerageData?.brokerageThroughSubagent[row.exchange]?.total_agent_brokerage?brokerageData?.brokerageThroughSubagent[row.exchange]?.total_agent_brokerage:0:0)}
              </TableCell>
              <TableCell>
                {row.status ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default WalletListModal;
