import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getPositions,getPositionsbyAgent,getPositionsbyUser } from '../../Services/ReportsService';
import { jwtDecode } from 'jwt-decode';// Assuming correct import based on usage
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid,IconButton,TextField,Button, Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Card, Tabs, Tab, Badge,
  DialogTitle,
  TablePagination, } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { NoEncryption, Visibility } from '@mui/icons-material';

const PositionSummary = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [showData3, setShowData3] = useState(false);
  const [ids, setId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const[page,setPage]=useState(0);
  const [pageSize,setPageSize]=useState(10)
  const[page2,setPage2]=useState(0);
  const [pageSize2,setPageSize2]=useState(10)
  const[page3,setPage3]=useState(0);
  const [pageSize3,setPageSize3]=useState(10)
  const[count,setCount]=useState(0);
  const[count2,setCount2]=useState(0);
  const[count3,setCount3]=useState(0);
  const[loading,setloading]=useState(false)
  const[loading2,setloading2]=useState(false)
  const[loading3,setloading3]=useState(false)
  const[row,setRow]=useState();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  }, []);

  useEffect(() => {
    if (ids) {
      fetchPositions(ids);
    }
  }, [ids,page,pageSize,searchQuery]);
  useEffect(() => {
    if (selectedRecord) {
      fetchPositionsbyAgents(ids,selectedRecord?.financial_instrument_id);
    }
  }, [page2,pageSize2,selectedRecord]);
  useEffect(() => {
 if (row){
      fetchPositionsbyUsers(row?.oversee_user, row?.financial_instrument_id);
 }
  }, [page3,pageSize3,row]);

  const fetchPositions = async (user_id) => {
    try {
      setloading(true)
      const records = await getPositions(user_id,page+1,pageSize,searchQuery);
      const dataWithIds = records?.data.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
      setCount(records.total_count);
      setData(dataWithIds);
    } catch (error) {
      setloading(false)
      console.error('Error fetching positions: ', error);
    }finally{
      setloading(false)
    }
  };

  const fetchPositionsbyAgents = async (user_id,instrumentid) => {
    try {
      setloading2(true)
      const records = await getPositionsbyAgent(user_id,instrumentid,page2+1,pageSize2);
      const dataWithIds = records?.data.map((item, index) => ({
        ...item,
        no: index + 1, // Replacing id with no for incremental count
      }));
      setData2(dataWithIds);
      setCount2(records.total_count);
    } catch (error) {
      setloading2(false)
      console.error('Error fetching positions: ', error);
    }finally{
      setloading2(false)
    }
  };

  const fetchPositionsbyUsers = async (user_id,instrumentid) => {
    try {
      setloading3(true)
      const records = await getPositionsbyUser(user_id,instrumentid,page3+1,pageSize3);
      const dataWithIds = records?.data.map((item, index) => ({
        ...item,
        no: index + 1, // Replacing id with no for incremental count
      }));
      setCount3(records.total_count)
      setData3(dataWithIds);
    } catch (error) {
      setloading3(false)
      console.error('Error fetching positions: ', error);
    }finally{
      setloading3(false)
    }
  };



  const handleCountClick = (record) => {
    setRow(record)
    fetchPositionsbyUsers(record.oversee_user, record.financial_instrument_id);
    setShowData3(true); 
  };

  const columns = [
    { 
      field: 'no', 
      headerName: 'No', 
      flex: 1,
     hide:true
    },
    { field: 'trading_symbol', headerName: 'Trading Symbol', flex: 1 },
     { field: 'exchange', headerName: 'Exchange', flex: 1 },
    { field: 'instrument_count', headerName: 'Instrument Count', flex: 1,
    renderCell: (params) => (
      <IconButton onClick={() => handleOpenModal(params.row)} style={{ textDecoration: 'underline' }}>
        {params.value}
      </IconButton>
    ), },
  ];
  const columnVisibilityModel = {
    no: false,
  };
    const columns2 = [
      { 
        field: 'no', 
        headerName: 'No', 
        flex: 1,
      },
      { field: 'trading_symbol', headerName: 'Trading Symbol', flex: 1 },
      { field: 'oversee_user', headerName: 'Oversee_User', flex: 1 },
      { 
        field: 'full_name', 
        headerName: 'Name', 
        flex: 1,
        valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`
      },
      { field: 'instrument_count', headerName: 'Instrument Count', flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleCountClick(params.row)} style={{ textDecoration: 'underline' }}>
          {params.value}
        </IconButton>
      ),
    },
    ];
  const columns3 = [
    { 
      field: 'no', 
      headerName: 'No', 
      flex: 1,
     display:"none"
    },
     { field: 'trading_symbol', headerName: 'Trading Symbol', flex: 1 },
    { field: 'oversee_user', headerName: 'Oversee_User', flex: 1 },
    { field: 'user_id', headerName: 'User_Id', flex: 1 },
    { 
      field: 'full_name', 
      headerName: 'UserName', 
      flex: 1,
      valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`
    },
    { field: 'instrument_count', headerName: 'Instrument Count', flex: 1,},
  ];
  const handleOpenModal = (record) => {
    setSelectedRecord(record);
    fetchPositionsbyAgents(ids,record.financial_instrument_id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setShowData3(false);
    setPage2(0);
    setPage3(0);
    setData2([]);
    setData3([])
  }
  const handleBack = () => {
    setShowData3(false);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(0);
  };
  const handleChange2 = (event, newPage) => {
    setPage2(newPage);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setPageSize2(parseInt(event.target.value));
    setPage2(0);
  };
  const handleChange3 = (event, newPage) => {
    setPage3(newPage);
  };
  const handleChangeRowsPerPage3 = (event) => {
    setPageSize3(parseInt(event.target.value));
    setPage3(0);
  };
  return (
    <>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} mt={1}>
        <TextField
          sx={{ ml: 1 }}
          placeholder="trading Symbol"
          inputProps={{ 'aria-label': 'search with name' }}
          onChange={(e) => setSearchQuery(e.target.value.toUpperCase())}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Grid>
      <Grid mt={2}>
      <DataGrid
                rows={data}
                columns={columns}
                 getRowId={(data) => data.financial_instrument_id}
                 autoHeight={data.length===0}
                 sx={{
                   maxWidth: '97%',
                   maxHeight: data.length > 0 ? '90vh' : 'auto',
                   minHeight: data.length ? '400px' : '100px', // Conditionally setting minHeight
                 }}
                   hideFooterPagination
        hideFooter='true'   
        columnVisibilityModel={columnVisibilityModel}
              loading={loading}
               />
                <Box display={"flex"} justifyContent={"flex-end"}>
     <TablePagination
   component="div"
   count={count}
   page={page}
   onPageChange={handleChange}
   rowsPerPage={pageSize}
   onRowsPerPageChange={handleChangeRowsPerPage}
 />            </Box>
      </Grid>
      <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth="md"
          maxWidth="md"
        >
          <DialogTitle textAlign={'center'}>{selectedRecord?.tradingsymbol} {showData3 ?"User Level":"Agent Level"} </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
          <Grid mt={2}>
{showData3 ? (
            <>
              <Button onClick={handleBack} sx={{ mb: 2 }}>Back</Button>
              <DataGrid
                rows={data3}
                columns={columns3}
                getRowId={(data) => data.no}
                autoHeight={data3.length===0}
                sx={{
                  maxWidth: '97%',
                  maxHeight: data3.length > 0 ? '80vh' : 'auto',
                  minHeight: data3.length ? '300px' : '100px', // Conditionally setting minHeight
                }}
                hideFooterPagination
                hideFooter='true'
                loading={loading3}
                columnVisibilityModel={columnVisibilityModel} 
              />
              <Box display={"flex"} justifyContent={"flex-end"}>
     <TablePagination
   component="div"
   count={count3}
   page={page3}
   onPageChange={handleChange3}
   rowsPerPage={pageSize3}
   onRowsPerPageChange={handleChangeRowsPerPage3}
 />            </Box>
            </>
          ) : (
            <>
            <DataGrid
              rows={data2}
              columns={columns2}
              getRowId={(data) => data.no}
              autoHeight={data2.length===0}
              sx={{
                maxWidth: '97%',
                maxHeight: data2.length > 0 ? '90vh' : 'auto',
                minHeight: data2.length ? '300px' : '100px', // Conditionally setting minHeight
              }}
              hideFooterPagination
        hideFooter='true' 
        loading={loading2}
        columnVisibilityModel={columnVisibilityModel}
            />
            <Box display={"flex"} justifyContent={"flex-end"}>
     <TablePagination
   component="div"
   count={count2}
   page={page2}
   onPageChange={handleChange2}
   rowsPerPage={pageSize2}
   onRowsPerPageChange={handleChangeRowsPerPage2}
 />            </Box>
 </>
          )}
      </Grid>
          </DialogContent>
        </Dialog>
    </>
  );
};

export default PositionSummary;