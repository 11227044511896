import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Card,
  Tabs,
  Tab,
  Badge,
  FormControlLabel,
  Switch,
  DialogTitle
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddHoliday from './AddHoliday';
import CloseIcon from '@mui/icons-material/Close';
import { getHolidays, deleteHoliday, getHolidayCounts } from '../../Services/HolidayService';
import EditHoliday from './EditHoliday';

function CalenderList() {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('NSE');
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [Del, setDel] = useState(false);
  const [rowData, setRowData] = useState();
  const [visibleOnly, setVisibleOnly] = useState(true);
  const[loading,setLoading]=useState(false);
  const fetchHolidays = async (type) => {
    try {
      setLoading(true)
      const records = await getHolidays(type);
      const visibleRecords = records.filter(record => record.is_visible === visibleOnly);
      setData(visibleRecords);
    } catch (error) {
      setLoading(false)
      console.error('Error fetching holidays: ', error);
    }finally{
      setLoading(false)
    }
  };
  const fetchCounts = async () => {
    try {
      const records = await getHolidayCounts();
      setCountData(records);
    } catch (error) {
      console.error('Error fetching counts: ', error);
    }
  };

  useEffect(() => {
    fetchHolidays(activeTab);
    fetchCounts();
  }, [activeTab,visibleOnly]);

//   useEffect(() => {
//     const eventSource = new EventSource('http://localhost:8000/session');
//     eventSource.onmessage = (event) => {
//         // React based on the event received
//         // const data = JSON.parse(event.data);
//         console.log(event);
//     };
//     return () => eventSource.close();
// }, []);
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleModalClose = () => {
    fetchHolidays(activeTab);
    fetchCounts();
    setOpenNew(false);
    setOpenEdit(false);
  };
  const handleChangeVisibility = (event) => {
    setVisibleOnly(event.target.checked);

  };

  const ActiveColumns = [
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   flex: 1
    // },
    {
      field: 'reason',
      headerName: 'Occasion',
      flex: 1.5
    },
    {
      field: 'timezone',
      headerName: 'TimeZone',
      flex: 1
    },
    {
      field: 'market',
      headerName: 'Market',
      flex: 1
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1
    },
    {
      field: 'fullday',
      headerName: 'IsFullday',
      flex: 1
    },
    {
      field: 'starttime',
      headerName: 'Start Time',
      flex: 1
    },
    {
      field: 'endtime',
      headerName: 'End Time',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <Tooltip title="Edit" placement="top">
              <EditIcon style={{ color: '#ffd666' }} />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row)}>
            <Tooltip title="Delete" placement="top">
              <DeleteIcon style={{ color: '#ff7875' }} />
            </Tooltip>
          </IconButton>
        </>
      )
    }
  ];
  const findCountByMarket = (market) => {
    const countObj = countData.find((count) => count.market === market);
    return countObj ? countObj.holiday_count : 0;
  };
  const handleDelete = (row) => {
    setDel(true);
    setRowData(row);
  };
  const Delete = (id) => {
    deleteHoliday(id)
      .then((response) => {
        setDel(false);
        fetchHolidays(activeTab);
        //fetchCounts(ids);
        toast.success('Deleted successfully', {
          autoClose: 2000
        });
      })
      .catch((error) => {
        toast.error('Failed to delete', {
          autoClose: 2000
        });
      });
  };
  const handleEdit = (row) => {
    setRowData(row);
    setOpenEdit(true);
  };
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
      <Tab
        label={
          <span>
            NSE
            {/* <Badge badgeContent={findCountByMarket('NSE')} color="primary" style={{marginLeft:"15px"}} max={99999} showZero /> */}
          </span>
        }
        value="NSE"
      />
      <Tab
        label={
          <span>
            CRYPTO
            {/* <Badge badgeContent={findCountByMarket('CRYPTO')}  color="primary" style={{marginLeft:"15px"}} max={99999} showZero/> */}
          </span>
        }
        value="CRYPTO"
      />
      <Tab
        label={
          <span>
            NASDAQ
            {/* <Badge badgeContent={findCountByMarket('NASDAQ')}  color="primary" style={{marginLeft:"15px"}} max={99999} showZero/> */}
          </span>
        }
        value="NASDAQ"
      />
      <Tab
        label={
          <span>
            MCX
            {/* <Badge badgeContent={findCountByMarket('MCX')}  color="primary" style={{marginLeft:"15px"}} max={99999} showZero/> */}
          </span>
        }
        value="MCX"
      />
       <Tab
        label={
          <span>
            APPLICATION
            {/* <Badge badgeContent={findCountByMarket('APPLICATION')}  color="primary" style={{marginLeft:"15px"}} max={99999} showZero/> */}
          </span>
        }
        value="APPLICATION"
      />
    </Tabs>
        </Box>
      </Card>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} mt={3}>
        <Grid mr={2}>        <FormControlLabel
          control={<Switch checked={visibleOnly} onChange={handleChangeVisibility} />}
          label={visibleOnly ? 'Holidays' : 'Weekends'}
        />
        </Grid>
<Grid>
        <Button variant="outlined" color="success" onClick={() => setOpenNew(true)}>
          Add
        </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataGrid
          rows={data}
          columns={ActiveColumns}
          getRowId={(data) => data?.id}
          autoHeight={data?.length === 0}
          sx={{ maxWidth: '97%', maxHeight: data?.length > 0 ? '90vh' : 'auto', minHeight: data?.length ? '400px' : '100px' }}
          initialState={{
            ...data?.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 25, 50]}
          loading={loading}
        />
      </Grid>
      <Dialog open={openNew} onClose={() => setOpenNew(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Add New Holiday/Maintenance</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenNew(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <AddHoliday modalHandleCallback={handleModalClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Update Calender</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenEdit(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <EditHoliday openEdit={openEdit} data={rowData} modalHandleCallback={handleModalClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={Del} onClose={() => setDel(false)} fullWidth="sm" maxWidth="sm">
        <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>Confirm</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <p>Are you sure you want to Delete row with Id = {rowData?.id}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDel(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => Delete(rowData?.id)} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Grid>
  );
}

export default CalenderList;
