const env=process.env.REACT_APP_API_URL;
export const getPortifoliovalue = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(`${env}/getCurrentPortfolio/${userId}`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      const err = await response.json();
      console.error('Portfolio API error response:', err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error('Error fetching portfolio value:', error);
    throw error;
  }
};


export const getPortfolioWeekly = async (user_id,agent_id, year, week_number, start_date, end_date, limit, offset) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    // Building query parameters dynamically
    const queryParams = new URLSearchParams();
    if (user_id) queryParams.append('user_id', user_id);
    if (agent_id) queryParams.append('agent_id', agent_id);
    if (year) queryParams.append('year', year);
    if (week_number) queryParams.append('week_number', week_number);
    if (start_date) queryParams.append('start_date', start_date);
    if (end_date) queryParams.append('end_date', end_date);
    if (limit) queryParams.append('limit', limit);
    if (offset) queryParams.append('offset', offset);

    const url = `${env}/weekly_portfolio/?${queryParams.toString()}`;

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      const err = await response.json();
      console.error('Portfolio API error response:', err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error('Error fetching portfolio value:', error);
    throw error;
  }
};

 