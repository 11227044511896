import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Box, Card, Grid, Tab, Tabs, TextField, Button, Typography, TablePagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getClosing } from '../../Services/UserConfigService';
import {jwtDecode} from 'jwt-decode';
function ClosingPrice() {
  const [category, setCategory] = useState('NSE');
  const [zTokensData, setZTokensData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [priceUpdates, setPriceUpdates] = useState({});
  const [id, setId] = useState();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      setId(decodedToken.UserID);
    }
  }, []);
  useEffect(() => {
    if(id){
    fetchZTokens();
    }
  }, [page, category, searchTerm,pageSize,id]);

  const fetchZTokens = useCallback(async () => {
    try {
      setLoading(true);
      const { data: tokenData, total_count: countNum } = await getClosing(id, category, searchTerm, pageSize, page);
      
      // Parse each stringified JSON object in the data array
      const parsedData = tokenData.map(item => JSON.parse(item));
      
      setZTokensData(parsedData);
      setCount(countNum);
    } catch (error) {
      console.error('Error fetching tokens: ', error);
    } finally {
      setLoading(false);
    }
  }, [category, searchTerm, page, pageSize, id]);
  
  console.log(zTokensData);
  
  const handleChange = (newValue) => {
    setCategory(newValue);
  };

  const handlePriceChange = (id, value) => {
    setPriceUpdates((prev) => ({ ...prev, [id]: value }));
  };

  const handleSave = (id, exchange) => {
    const updatedPrice = priceUpdates[id];
    console.log(`Updated Price for Exchange ${exchange}, Zid ${id}: ${updatedPrice}`);
  };

  const columns = [
    { field: 'trading_symbol', headerName: 'Name', flex: 1 },
    { field: 'close', headerName: 'Closing Price', flex: 1 },
    {
      field: 'new_price',
      headerName: 'New Closing Price',
      flex: 1,
      renderCell: (params) => (
        <TextField
          variant="outlined"
          size="small"
          value={priceUpdates[params.row.zid] || ''}
          onChange={(e) => handlePriceChange(params.row.zid, e.target.value)}
          placeholder="new closing price"
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled
          onClick={() => handleSave(params.row.zid, params.row.exchange)}
        >
          Save
        </Button>
      ),
    },
  ];
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Tabs value={category} aria-label="category">
        <Tab label="NSE" value="NSE" onClick={() => handleChange('NSE')} />
        <Tab label="FUTURES" value="NFO-FUT" onClick={() => handleChange('NFO-FUT')} />
        <Tab label="CRYPTO" value="CRYPTO" onClick={() => handleChange('CRYPTO')} />
        <Tab label="NASDAQ" value="NASDAQ" onClick={() => handleChange('NASDAQ')} />
        <Tab label="MCX" value="MCX" onClick={() => handleChange('MCX')} />
      </Tabs>

      <Grid mt={2}>
       
          <TextField
            label="Search...."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            sx={{ m: 2, width: '300px' }}
          />
       

      <Box>
        <DataGrid
          rows={zTokensData}
          columns={columns}
          getRowId={(row) => row.financial_instrument_id}
        
          loading={loading}
          autoHeight={zTokensData.length === 0}
          sx={{ maxWidth: '97%', maxHeight: zTokensData.length > 0 ? '90vh' : 'auto', minHeight: zTokensData.length ? '400px' : '100px' }}
          hideFooterPagination
          hideFooter='true'
        />
         <Box display={"flex"} justifyContent={"flex-end"}>
        <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />            </Box>
      </Box>

      </Grid>
    </Grid>
  );
}

export default ClosingPrice;
