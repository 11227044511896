import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, TextField, Button, FormHelperText, Autocomplete, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createUser, getAllAgents, resetPassword, updateUser } from '../../Services/UserService';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
function EditSubAgent(props) {
  const { modalHandleCallback, openEdit, data } = props;
  
  const [role, setRole] = useState();
  const[userId,setUserId]=useState();
  const[password,setPassword]=useState();
  const[show,setShow]=useState(false);
  const[loading,setLoading]=useState(false)

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setRole(decodedToken.UserRole);
    if (openEdit) {
      validation.setFieldValue('firstName', data?.first_name);
      validation.setFieldValue('lastName', data?.last_name);
      validation.setFieldValue('email', data?.email);
    }
  }, []);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    balance: '',
    password: ''
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter the First Name'),
      lastName: Yup.string().required('Please Enter the Last Name'),
      email: Yup.string()
        .required('Please Enter the Email')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format')
      // balance: Yup.number().positive('Please Enter a vaild Amount')
    }),
    onSubmit: (values) => {
      const updatedAgent = {
        user_id: data?.user_id,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        user_role: 'SA'
      };

      updateUser(updatedAgent)
        .then((response) => {
          toast.success('Agent updated successfully', {
            autoClose: 2000
          });
          validation.resetForm({ values: defaultValues });
          setTimeout(() => {
            modalHandleCallback();
          }, 1000);
        })
        .catch((error) => {
          toast.error('Failed to update Agent', {
            autoClose: 2000
          });
        });
    }
  });
  const reset=()=>{
    let userData={
      user_id:data?.user_id
    }
    setLoading(true)
    resetPassword(userData).then((response)=>{
      toast.success('password reset successfully', { autoClose: 2000 });
      setShow(true);
      setUserId(response.user_id);
      setPassword(response.new_password);
    }).catch(()=>{
      setLoading(false)
      toast.error('failed to reset password', { autoClose: 2000 });
    }).finally(()=>{
      setLoading(false)
    })
  }
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!', { autoClose: 2000 });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy to clipboard.', { autoClose: 2000 });
    });
  };
  return (
    <>
      <form onSubmit={validation.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              type="text"
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={validation.values.firstName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
            />
            {validation.errors.firstName && validation.touched.firstName ? (
              <FormHelperText error>{validation.errors.firstName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={validation.values.lastName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
            />
            {validation.errors.lastName && validation.touched.lastName ? (
              <FormHelperText error>{validation.errors.lastName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Email"
              varian="outlined"
              fullWidth
              name="email"
              value={validation.values.email || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.email && validation.touched.email ? true : false}
            />
            {validation.errors.email && validation.touched.email ? <FormHelperText error>{validation.errors.email}</FormHelperText> : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
          {show && (
            <Box bgcolor="#f0f0f0" p={1} borderRadius="borderRadius">
          <Box display={'flex'} flexDirection={'row'}>
          <Typography variant="h6" mt={0.5} mr={2}>New User Credentials</Typography>
          <IconButton onClick={() => copyToClipboard(`UserID: ${userId}\nPassword: ${password}`)} aria-label="copy">
            <ContentCopyIcon />
          </IconButton>
          </Box>
          <Typography variant="body1">Email:&nbsp;&nbsp; {data?.email}</Typography>
          <Typography variant="body1">Password:&nbsp;&nbsp; {password}</Typography>
          </Box>
          )}
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2} spacing={2}>
          {role=='AU'&&(
            <Box mr={2}>
            <Button
              variant="outlined"
              color="warning"
              onClick={()=>reset()}
            >
             {loading ? <CircularProgress size={24} /> : "Reset password"}
            </Button>
            </Box>
            )}
            <Box>
            <Button
              type="submit" variant="outlined" color="success"
            >
               Update SubAgent
            </Button>
            </Box>
          </Grid>
      </form>
      <ToastContainer />
    </>
  );
}

export default EditSubAgent;
