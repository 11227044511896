const env=process.env.REACT_APP_API_URL;

export const update_expiry = async (financial_instrument_id,expiry,exchange) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
    const response = await fetch(`${env}/zinstruments/${financial_instrument_id}/${expiry}/${exchange}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error mapping data: ', error);
    throw error;
  }
};

export const getZTokens = async (category, searchTerm, page, pageSize) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }

    const response = await fetch(`${env}/zinstruments/${category}?search_keyword=${searchTerm}&page=${page}&page_size=${pageSize}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};
export const getUserZTokens = async (user_id, instrument_type, keyword, limit, offset) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
   
    // Initialize URLSearchParams and add pagination parameters
    const queryParams = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.toString()
    });

    // Only add the 'keyword' parameter if it's not empty
    if (keyword.trim() !== "") {
      queryParams.append('keyword', keyword);
    }

    const response = await fetch(`${env}/get-zinstruments/${user_id}/${instrument_type}?${queryParams}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};

  export const getZTokensByAgent = async (user_id, type) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/agent-token-mapping/${user_id}/?type=${type}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };
  

  export const mappingToken = async (NseData) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/agent-token-mapping/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(NseData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };
 
  export const mappingAllTokens = async (Data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/add-financial-instruments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(Data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const getAllTokenConfig = async (user_id,exchange) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/check-all-instruments?user_id=${user_id}&exchange=${exchange}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const userConfig = async (config) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/upsert-user-config`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(config),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const squareoffConfig = async (config,user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      } 
      const payload = { config };
      const response = await fetch(`${env}/user-leverageconfig/${user_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error mapping data: ', error);
      throw error;
    }
  };

  export const getUserConfig = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/user-config/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getLeverageConfig = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/user-leverageconfig/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const limitTokenUser = async (data) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/post-limit-zinstruments`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  export const getUserlimitTokens = async (user_id,type) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
     
      const response = await fetch(`${env}/user-limits/${user_id}/${type}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status == 404){
        return [];
      }
      else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };