import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

const UserDisableModal = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const env=process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (location.pathname === '/login') {
        setIsDisabled(false)
        return; // Do not proceed with checking user status
      }
    const token = sessionStorage.getItem('authToken');
    if (!token) return;
    const decodedToken = jwtDecode(token); // Assumed jwt-decode is the correct function
    const user_id=decodedToken.UserID;
    const intervalId = setInterval(() => {
    const tok = sessionStorage.getItem('authToken');
    if (!tok) return;
      fetch(`${env}/user/status/${user_id}`, {
        headers: {
          'Authorization': `Bearer ${tok}`
        }
      })
      .then(res => res.json())
      .then(data => {
        setIsDisabled(!data.is_enabled[0]); // Assuming the data returned has an is_enabled flag
        if (!data.is_enabled[0]) {
          clearInterval(intervalId); // Stop the interval if the user is disabled
        }
      })
      .catch(error => console.error('Error checking user status:', error));
    }, 10000); // Check every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    setIsDisabled(false)
    // Clear user session, local storage or any auth tokens
    sessionStorage.removeItem('authToken');
    // Redirect to login
    navigate('/login');
  };

  return (
    <Dialog open={isDisabled} onClose={() => {}}>
      <DialogTitle>Your account has been disabled. Please Contact Admin.</DialogTitle>
      <Button onClick={handleLogout}>Ok</Button>
    </Dialog>
  );
};

export default UserDisableModal;
