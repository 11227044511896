import React from 'react'
import './NotFound.css';
import { useNavigate } from 'react-router-dom';
function NotFound() {
    const navigate = useNavigate();
  return (
    <div className="not-found-container">
    <div className="error-text">
      <h1>404</h1>
      <h2>Oops! Page not found.</h2>
    </div>
    <p className="description">
      We can't seem to find the page you're looking for.
    </p>
    <button className="home-button" onClick={()=>  navigate('/user-list')}>Go Home</button>
  </div>
  )
}

export default NotFound