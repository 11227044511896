// assets
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { jwtDecode } from "jwt-decode";
// icons
const icons = {
  PersonOutlineOutlinedIcon,
  SupportAgentOutlinedIcon,
  SummarizeOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  SettingsIcon,
  PaymentsIcon,
  SummarizeIcon,
  CalendarMonthIcon,
  ManageAccountsIcon,
  MoreTimeIcon,
  ReceiptLongIcon,
  AccessAlarmIcon,
  ShowChartIcon,
  CopyAllIcon,
  PriceChangeIcon
};
const getCurrentUserRole = () => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) return null;

    const decodedToken = jwtDecode(token);
    return decodedToken.UserRole;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

const currentUserRole = getCurrentUserRole();
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
console.log(currentUserRole);
const dashboard = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'Users',
      title: 'Users',
      type: 'item',
      url: '/user-list',
      icon: icons.PersonOutlineOutlinedIcon,
      breadcrumbs: false
    },
    ...(currentUserRole !== 'AG' && currentUserRole !== 'SA'&& currentUserRole !== 'SU'? [{
      id: 'Agents',
      title: 'Agents',
      type: 'item',
      url: '/agent-list',
      icon: icons.SupportAgentOutlinedIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SA'&& currentUserRole !== 'SU' ? [{
      id: 'SubAgents',
      title: 'Sub Agents',
      type: 'item',
      url: '/subagent-list',
      icon: icons.SupportAgentOutlinedIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'AG' && currentUserRole !== 'SA'&& currentUserRole !== 'SU'? [{
      id: 'Support Users',
      title: 'Support Users',
      type: 'item',
      url: '/support-list',
      icon: icons.SupportAgentOutlinedIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' ? [{
      id: 'Position Summary',
      title: 'Position Summary',
      type: 'item',
      url: '/position-summary',
      icon: icons.SummarizeIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' ? [{
      id: 'Weekly Portfolio',
      title: 'Weekly Portfolio',
      type: 'item',
      url: '/weekly-portfolio',
      icon: icons.ShowChartIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SA'&& currentUserRole !== 'SU'? [{
      id: 'FutureExpiry',
      title: 'Futures-Expiry',
      type: 'item',
      url: '/futures-expiry',
      icon: icons.AccessAlarmIcon,
      breadcrumbs: false
    }] : []),
    ...( currentUserRole !== 'SU'? [{
      id: 'ClosingPrice',
      title: 'Closing-Price',
      type: 'item',
      url: '/closing-price',
      icon: icons.PriceChangeIcon,
      breadcrumbs: false
    }] : []),
    {
      id: 'Reports',
      title: 'User-Reports',
      type: 'item',
      url: '/reports',
      icon: icons.SummarizeOutlinedIcon,
      breadcrumbs: false
    },
    ...(currentUserRole !== 'SA'&& currentUserRole !== 'SU'? [{
      id: 'BrokerageReports',
      title: 'Brokerage Reports',
      type: 'item',
      url: '/brokerage-report',
      icon: icons.SummarizeOutlinedIcon,
      breadcrumbs: false
    }] : []),
    {
      id: 'AgentTransactions',
      title: 'Agent-Transactions',
      type: 'item',
      url: '/agent-transactions',
      icon: icons.ReceiptLongIcon,
      breadcrumbs: false
    },
    ...(currentUserRole !== 'SU' ? [{
      id: 'Payments',
      title: 'Payments',
      type: 'item',
      url: '/payments',
      icon: icons.PaymentsIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' ? [{
      id: 'Config',
      title: 'Agent Config',
      type: 'item',
      url: '/config',
      icon: icons.SettingsIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' ? [{
      id: 'UserConfig',
      title: 'User Config',
      type: 'item',
      url: '/user-config',
      icon: icons.ManageAccountsIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' ? [{
      id: 'CopyConfig',
      title: 'Copy Config',
      type: 'item',
      url: '/copy-config',
      icon: icons.CopyAllIcon,
      breadcrumbs: false
    }] : []),
    ...(currentUserRole !== 'SU' && currentUserRole !== 'SA' && currentUserRole !== 'AG'? [{
      id: 'Managetiming',
      title: 'Manage Timings',
      type: 'item',
      url: '/manage-timing',
      icon: icons.MoreTimeIcon,
      breadcrumbs: false
    }] : []),
    {
      id: 'Tickets',
      title: 'Tickets',
      type: 'item',
      url: '/tickets',
      icon: icons.ConfirmationNumberOutlinedIcon,
      breadcrumbs: false
    },
    ...(currentUserRole !== 'SU' && currentUserRole !== 'SA'? [{
      id: 'Manage Calender',
      title: 'Manage Calender',
      type: 'item',
      url: '/Manage-Calender',
      icon: icons.CalendarMonthIcon,
      breadcrumbs: false
    }] : []),
  ]
};

export default dashboard;
