import { Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText,Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
function UserActivityModal({open,setModalOpen}) {
    const navigate = useNavigate();
    const handleOk=()=>{
        setModalOpen(false);
        navigate('/login');
    }
  return (
    <Dialog open={open} onClose={handleOk} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">  {"Inactive Session"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      You have been inactive for a while. For your security, please log in again.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleOk} color="primary" autoFocus >
        ok
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default UserActivityModal