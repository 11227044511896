const env=process.env.REACT_APP_API_URL;
export const getAllTickets = async (status,user_id, searchKeyword, pageNumber, pageSize) => {
  try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
          throw new Error('Authentication token not found in session storage');
      }
      let url = `${env}/getall-tickets`;

      // Add status query parameter if it exists
      if (status) {
          url += `?status=${encodeURIComponent(status)}`;
      }
     if(user_id){
        url+=`&user_id=${encodeURIComponent(user_id)}`
      }
      // Add search query parameter if it exists
      if (searchKeyword) {
          // Check if status parameter already exists in the URL
          url += status ? `&search_keyword=${encodeURIComponent(searchKeyword)}` : `?search_keyword=${encodeURIComponent(searchKeyword)}`;
      }

      // Add pagination parameters
      url += `&page_number=${pageNumber}&page_size=${pageSize}`;

      const response = await fetch(url, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return await response.json();
  } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
  }
};

  export const getTicketCounts = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/tickets/status-counts?user_id=${user_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const updateTicket = async (id,status) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/update-ticket/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(status),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating ticket:', error);
      throw error;
    }
  };