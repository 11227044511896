import { getAllAgents, getAllSubAgents, getAllUsers } from '../../Services/UserService';
import { jwtDecode } from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getPortfolioWeekly } from '../../Services/PortifolioService';
import { DataGrid } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import { formatValueIndian } from '../../utils/Formatvalue';
function WeeklyPortfolio() {
  const [ids, setId] = useState();
  const [apiData, setApiData] = useState([]);
  const [apiPortfolio, setApiPortfolio] = useState([]);
  const [count, setCount] = useState();
  const [totals, setTotals] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState();
  const [selectedWeek, setWeek] = useState();
  const [options, setOptions] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [year, setYear] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [selected, setSelected] = useState();
  const [role, setRole] = useState('');
  const [allowApiCall, setAllowApiCall] = useState(false);
  const [searchQuery2, setSearchQuery2] = useState();
  const [Agent, setAgent] = useState();
  const [options2, setOptions2] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const weeks = Array.from({ length: 53 }, (v, k) => k + 1);
  const [isLoading, setIsLoading] = useState(false);
  const [agentId, setAgentId] = useState();
  const[currentweek,setCurrentWeek]=useState();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
    setRole(decodedToken.UserRole);
    setAgentId(decodedToken.UserID);
  }, []);
  dayjs.extend(customParseFormat);
  const today = dayjs();
  const currentYear = dayjs().year();
  const maxDate = dayjs(new Date(currentYear + 1, 0, 1)).subtract(1, 'day');
  const fetchUsers = async (user_id, keyword) => {
    try {
      const data = await getAllUsers('true', user_id, 1, 10, keyword);
      setApiData(data?.rows);
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };

  const fetchData = async (selected, Agent, year, selectedWeek, startDate, endDate, limit, offset) => {
    console.log(Agent)
    try {
      const data = await getPortfolioWeekly(selected, Agent, year, selectedWeek, startDate, endDate, limit, offset);
      setApiPortfolio(data.records);
      setCount(data.total_count);
      setTotals(data.totals_by_exchange);
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };

  const fetchAgents = async (bool, userId, search_query) => {
    try {
      setIsLoading(true);
      let agentsPromise, subAgentsPromise;
      if (role === 'AG') {
        subAgentsPromise = getAllSubAgents(bool, userId, 1, 10, search_query);
        agentsPromise = Promise.resolve([]);
      } else if (role === 'SA') {
        subAgentsPromise = Promise.resolve([]);
        agentsPromise = Promise.resolve([]);
      } else {
        agentsPromise = getAllAgents(bool, userId, 1, 10, search_query);
        subAgentsPromise = getAllSubAgents(bool, userId, 1, 10, search_query);
      }

      const [agents, subAgents] = await Promise.all([agentsPromise, subAgentsPromise]);
      let userOptions = {};
      let subAgentOptions = {};

      if (role === 'AG') {
        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      } else if (role === "AU") {
        userOptions = agents?.rows.map((user) => ({
          label: user.email,
          id: user.user_id
        }));

        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      }

      let finalOptions = [];
      if (role === 'AG') {
        finalOptions = [
          ...subAgentOptions,
          {
            label: sessionStorage.getItem('email'),
            id: agentId
          }
        ];
      }
      else if (role === 'SA') {
        finalOptions = [
          {
            label: sessionStorage.getItem('email'),
            id: agentId
          }
        ];
      } else {
        finalOptions = [
          ...userOptions,
          ...subAgentOptions
        ];
      }

      setOptions2(finalOptions);
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching agents
    }
  };
  useEffect(() => {
    if (ids) {
      fetchUsers(ids, searchKeyword);
      fetchAgents('true', ids, searchQuery2);
    }
  }, [ids, searchKeyword, searchQuery2]);

  useEffect(() => {
    setOptions(
      apiData.map((user) => ({
        label: user.email + ' (' + user.user_id + ')',
        id: user.user_id
      }))
    );
  }, [apiData]);

  useEffect(() => {
    if (selected || Agent) {
      fetchData(selected, Agent, year, selectedWeek, startDate, endDate);
    }
  }, [selected, year, selectedWeek, startDate, endDate, Agent]);
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
    const adjustedEndDate = newValue ? dayjs(newValue).add(1, 'day').format('YYYY-MM-DD') : null;
    setEnd(adjustedEndDate);
  };

  const handleSelect = (event, value) => {
    if (value) {
      setSelected(value ? value.id : ''); 
     
  } else {
      setSelected('');
      setApiPortfolio([]); 
      setTotals([])
  }
  };

  const handleWeekChange = (newValue) => {
    setWeek(newValue);
  };
  const handleYearChange = (newValue) => {
    setYear(newValue);
  };
  const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
    // timeZoneName: 'short'
  };
  const Columns = [
    { field: 'user_id', headerName: 'User ID', flex: 1 },
    { field: 'agent_id', headerName: 'Agent ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'exchange', headerName: 'Exchange', flex: 0.7 },
    {
      field: 'credit_balance',
      headerName: 'CreditBalance',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },
    {
      field: 'portfolio_value',
      headerName: 'P&L Value',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },
    {
      field: 'brokerage',
      headerName: 'Brokerage',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },
    {
      field: 'total',
      headerName: 'Week Settlement',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },

    { field: 'week_number', headerName: 'Week', flex: 0.5 },
    { field: 'week_start_date', headerName: 'Start Date', flex: 1 },
    { field: 'week_end_date', headerName: 'End Date', flex: 1 }
  ];
  const Columns2 = [
    { field: 'exchange', headerName: 'Exchange', flex: 0.7 },
    {
      field: 'total_credit_balance',
      headerName: 'TotalCreditBalance',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },
    {
      field: 'total_pnl',
      headerName: 'Toral P&L',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${params.value}`;
      }
    },
    {
      field: 'agent_brokerage',
      headerName: 'Brokerage',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${parseFloat(params.value).toFixed(2)}`;
      }
    },
    {
      field: 'total_net',
      headerName: 'Total Week Settlement',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${parseFloat(params.value).toFixed(2)}`;
      }
    },
    {
      field: 'subagent_brokerage',
      headerName: 'Additional Brokerage',
      flex: 1,
      renderCell: (params) => {
        const currencySymbol = params.row.exchange === 'MCX' || params.row.exchange === 'NSE' ? '₹' : '$';
        return `${currencySymbol} ${parseFloat(params.value).toFixed(2)}`;
      }
    }
  ];

  const handleSelection = (event, value) => {
    if (value) {
      setAgent(value ? value.id:'');
     
  } else {
      setAgent('');
      setApiPortfolio([]); 
      setTotals([])
  }
  
  };
  const handleInputChange = (event, newValue) => {
    setSearchQuery2(newValue);
  };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  useState(() => {
    const getCurrentWeekNumber = () => {
        const now = new Date();
        const yearStart = new Date(now.getFullYear(), 0, 1);
        const firstDayOfYear = yearStart.getDay();
        const startOfFirstWeek = yearStart.getDate() - firstDayOfYear + ((firstDayOfYear === 0) ? -6 : 1);
        yearStart.setDate(startOfFirstWeek);

        const dayMs = 24 * 60 * 60 * 1000;
        const weekMs = 7 * dayMs;
        const nowMs = now.getTime();
        const startOfYearMs = yearStart.getTime();
        const elapsedMs = nowMs - startOfYearMs;

        const weekNumber = Math.floor(elapsedMs / weekMs) + 1;
        return weekNumber;
    };

    setCurrentWeek(getCurrentWeekNumber());
    setWeek(getCurrentWeekNumber());
}, []);


  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* <Typography>Filters</Typography> */}
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mt={0}>
        <Grid mt={1}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{ width: 240 }}
            onChange={handleSelect}
            onInputChange={(event, newInputValue) => {
              setSearchKeyword(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User ID"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.875rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid mt={1}>
          <Autocomplete
            sx={{ width: 240 }}
            options={options2}
            getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
            onChange={handleSelection}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Agent / SubAgent"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.875rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid mt={1}>
          <Autocomplete
            value={selectedWeek}
            onChange={(event, newValue) => {
              handleWeekChange(newValue);
            }}
            sx={{ width: 110 }}
            options={weeks}
            clearIcon={false}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Week"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.875rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
          <Typography variant="body2">
          Current Week: {currentweek}
        </Typography>
        </Grid>
        <Grid ml={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateRangePicker']}>
              <DemoItem>
                <DatePicker
                  views={['year']}
                  label="Year"
                  sx={{ width: 130 }}
                  // slotProps={{
                  //   field: { clearable: true }
                  // }}
                  value={year}
                  onChange={(newValue) => handleYearChange(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={maxDate}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} mt={0}>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DemoItem>
                  <DatePicker
                    label="From"
                    slotProps={{
                      field: { clearable: true }
                    }}
                    sx={{ width: 170 }}
                    value={startDate}
                    maxDate={today}
                    onChange={(newValue) => (setStart(newValue), handleStartDateChange(newValue))}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid ml={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DemoItem>
                  <DatePicker
                    label="To"
                    sx={{ width: 170 }}
                    slotProps={{
                      field: { clearable: true }
                    }}
                    value={endDate}
                    minDate={startDate ? dayjs(startDate) : null}
                    maxDate={today}
                    onChange={(newValue) => handleEndDateChange(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1} justifyContent={'center'}>
        <Grid item xs={9}>
          <Card>
            <CardContent style={{ paddingTop: 0 }}>
              {' '}
              {/* Remove padding from CardContent */}
              <DataGrid
                rows={totals}
                columns={Columns2}
                getRowId={(data) => data.exchange}
                autoHeight={totals.length === 0}
                //  sx={{
                //    maxWidth: '98%',
                //    maxHeight: totals.length > 0 ? '90vh' : 'auto',
                //    minHeight: totals.length ? '400px' : '100px', // Conditionally setting minHeight
                //  }}
                hideFooterPagination
                hideFooter="true"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid mt={2}>
        <DataGrid
          rows={apiPortfolio}
          columns={Columns}
          sortModel={[
            {
              field: 'created_at',
              sort: 'desc'
            }
          ]}
          getRowId={(data) => data.id}
          autoHeight={apiPortfolio.length === 0}
          sx={{
            maxWidth: '98%',
            maxHeight: apiPortfolio.length > 0 ? '90vh' : 'auto',
            minHeight: apiPortfolio.length ? '400px' : '100px' // Conditionally setting minHeight
          }}
          hideFooterPagination
          hideFooter="true"
        />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{' '}
        </Box>
      </Grid>
    </Grid>
  );
}

export default WeeklyPortfolio;
