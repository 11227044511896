import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, TextField, Button, FormHelperText, Autocomplete,Typography,CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { copyAgentConfig, createUser,getAllAgents, getAllSubAgents } from '../../Services/UserService';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function AddAgentModal(props) {
  const [agentId, setAgentId] = useState();
  const [agent, setAgent] = useState();
  const [role, setRole] = useState();
const[value,setValue]=useState();
  const [options, setOptions] = useState();
  const [options2, setOptions2] = useState();
  const [agentName, setAgentName] = useState('');
  const [searchQuery, setSearchQuery] = useState();
  const [selected, setSelected] = useState();
  const [search,setSearch]=useState();
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [isCopyConfigsChecked, setIsCopyConfigsChecked] = useState(false);
  const [id,setId]=useState();
  const [isLoading2, setIsLoading2] = useState(false);
  const[show,setShow]=useState(false);
  const[userId,setUserId]=useState();
  const[password,setPassword]=useState();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole)
  }, []);
  const { modalHandleCallback } = props;

  const fetchAgents = async (bool,id,search_query) => {
    try {
      const data = await getAllAgents(bool,id,1,10,search_query);
      setOptions(data?.rows);
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    }
  };
  const fetchAllAgents = async (bool, userIds, search) => {
    try {
      setIsLoading(true); // Set loading state to true when fetching agents
      let agentsPromise, subAgentsPromise;
      if (role === 'AG') {
        subAgentsPromise = getAllSubAgents(bool, userIds, 1, 10, search);
        agentsPromise = Promise.resolve([]);
      } else if (role === 'SA') {
        subAgentsPromise = Promise.resolve([]);
        agentsPromise = Promise.resolve([]);
      } else {
        agentsPromise = getAllAgents(bool, userIds, 1, 10, search);
        subAgentsPromise = getAllSubAgents(bool, userIds, 1, 10, search);
      }

      const [agents, subAgents] = await Promise.all([agentsPromise, subAgentsPromise]);
      let userOptions = {};
      let subAgentOptions = {};

      if (role === 'AG') {
        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      } else if (role === "AU") {
        userOptions = agents?.rows.map((user) => ({
          label: user.email,
          id: user.user_id
        }));

        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      }

      let finalOptions = [];
      if (role === 'AG') {
        finalOptions = [
          ...subAgentOptions.filter(option => option.label !== userId), // Filter out the user ID
          {
            label: sessionStorage.getItem('email'),
            id: agentId // Assuming 'userId' is stored in sessionStorage
          }
        ];
      }
      else if (role === 'SA') {
        finalOptions = [
          {
            label: sessionStorage.getItem('email'),
            id: agentId // Assuming 'userId' is stored in sessionStorage
          }
        ];
      } else {
        finalOptions = [
          ...userOptions.filter(option => option.label !== userId), // Filter out the user ID
          ...subAgentOptions.filter(option => option.label !== userId) // Filter out the user ID
        ];
      }
  
      // Exclude the final self-reference if necessary
      finalOptions = finalOptions.filter(option => option.label !== userId);
  
      setOptions2(finalOptions);
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching agents
    }
  };
 
  const handleInputChange = (event, newValue) => {
    setSearchQuery(newValue);
  };

  useEffect(()=>{
    if(agentId){
      fetchAgents("true",agentId,searchQuery);
      fetchAllAgents("true",agentId,search);
    }
    if(role === 'AG'){
      setAgent(sessionStorage.getItem('email'));
      setAgentName(sessionStorage.getItem('email').split('@')[0])
    }
  },[agentId,searchQuery,search])

  

  const handleRoleChange = (event, newValue) => {
    // const selectedAgent = apidata.find(item => item.email === newValue);
    setAgent(newValue?newValue.user_id:'');
    setAgentName(newValue ? newValue.email.split('@')[0] : '');
  };
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
   
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter the First Name'),
      lastName: Yup.string().required('Please Enter the Last Name'),
      email: Yup.string()
        .required('Please Enter the Email')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
    const newSubAgent={
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          user_role: 'SA',
          oversee_user: role === "AU" ? agent : agentId
        
    }
    setIsLoading(true);
        createUser(newSubAgent).then((response)=>{
          toast.success('SubAgent created successfully', { autoClose: 2000 });
          setShow(true);
          setId(response.user_id)
          setUserId(response.email);
          setPassword(response.password);
        }).catch((error)=>{
          let errorMessage = 'Failed to create SubAgent';
          try{
            const errorDetail = JSON.parse(error.message);
            if (errorDetail && errorDetail.detail) {
                errorMessage = errorDetail.detail;
            }
          } catch (parseError) {
               console.error('Error parsing JSON response:', parseError);
             }finally{
              setSubmitting(false);
              setIsLoading(false);
             }
             toast.error(errorMessage, { autoClose: 2000 });
             setSubmitting(false);
             setIsLoading(false);
        })
      }
  });

const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    toast.error('Clipboard not available. Try a different browser.', { autoClose: 2000 });
    return;
  }

  navigator.clipboard.writeText(text)
    .then(() => {
      toast.success('Copied to clipboard!', { autoClose: 2000 });
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy to clipboard.', { autoClose: 2000 });
    });
};

  const generateEmail = () => {
    if(validation.values.firstName && validation.values.lastName && agentId) {
      const email = `${validation.values.firstName}${validation.values.lastName}.${agentName}@gmail.com`.toLowerCase();
      validation.setFieldValue('email', email);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsCopyConfigsChecked(event.target.checked);
  };
  const handleSelect2 = (event, value) => {
    setAutocompleteValue(value);
    setSelected(value ? value.id : '');
  };
  const handleInput2 = (event, newValue) => {
    setSearch(newValue);
  };
  const copy_config = () => {
    var config_data={
      new_user_id:id,
      old_user_id:selected,
    }
    setIsLoading2(true);
    copyAgentConfig(config_data).then((response) => {
      toast.success('AgentConfig copied successfully', { autoClose: 2000 });
    }).catch((error) => {
      toast.error('Failed to copy AgentConfig', { autoClose: 2000 });
    }).finally(() => {
      setIsLoading2(false);
    });
  }
  return (
    <>
      <form onSubmit={validation.handleSubmit}>
        <Grid container spacing={2}>
        {role!=="AG"&&(
          <Grid item xs={12} sm={6} mt={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              getOptionLabel={(option) => option.email + ' (' + option.user_id + ')'}
              sx={{ width: 350 }}
              onChange={handleRoleChange}
              onInputChange={handleInputChange}
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Agent"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px',
                      '& input': {
                        fontSize: '0.875rem' 
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '4px' 
                      }
                    }
                  }}
                />
              )}
            />
          </Grid>
          )}
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              type="text"
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={validation.values.firstName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
            />
            {validation.errors.firstName && validation.touched.firstName ? (
              <FormHelperText error>{validation.errors.firstName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={validation.values.lastName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
            />
            {validation.errors.lastName && validation.touched.lastName ? (
              <FormHelperText error>{validation.errors.lastName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Email"
              varian="outlined"
              fullWidth
              name="email"
              value={validation.values.email || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.email && validation.touched.email ? true : false}
            />
            {validation.errors.email && validation.touched.email ? <FormHelperText error>{validation.errors.email}</FormHelperText> : null}
          </Grid>
         
      
        {show && (
           <>
           <Grid item xs={12} sm={6}>
             <FormControlLabel
               control={
                 <Checkbox
                   checked={isCopyConfigsChecked}
                   onChange={handleCheckboxChange}
                   name="copyConfigs"
                   color="primary"
                 />
               }
               label="Copy config from another Agent/SubAgent"
             />
             {isCopyConfigsChecked && (
               <>
                 <Autocomplete
                   disablePortal
                   value={autocompleteValue}
                   id="combo-box-demo"
                   options={options2}
                   sx={{ width: '100%' }}
                   onChange={handleSelect2}
                   getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
                   onInputChange={handleInput2}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       label="Agent/SubAgent ID"
                       sx={{
                         '& .MuiInputBase-root': {
                           height: '40px', // Adjust the height as needed
                           '& input': {
                             fontSize: '0.75rem' // Adjust the font size as needed
                           },
                           '& .MuiOutlinedInput-notchedOutline': {
                             borderRadius: '4px' // Optional: Adjust border radius if needed
                           }
                         }
                       }}
                       />
             )}
                 />
                 <Box mt={2}>
                 <Button
                   variant="outlined"
                   color="success"
                   disabled={isLoading2 || !autocompleteValue}
                   onClick={copy_config}
                   
                 >
                   {isLoading2 ? <CircularProgress size={24} /> : "Copy Config"}
                 </Button>
                 </Box>
               </>
             )}
           </Grid>
        <Box mt={2} ml={2} p={2} bgcolor="#f0f0f0" borderRadius="borderRadius">
          <Box display={'flex'} flexDirection={'row'}>
          <Typography variant="h6" mt={0.5} mr={2}>User Credentials</Typography>
          <IconButton onClick={() => copyToClipboard(`Email: ${userId}\nPassword: ${password}`)} aria-label="copy">
            <ContentCopyIcon />
          </IconButton>
          </Box>
          <Typography variant="body1">Email:&nbsp;&nbsp; {userId}</Typography>
          <Typography variant="body1">Password:&nbsp;&nbsp; {password}</Typography>
          
        </Box>
        </>
      )}
        </Grid>
        {!show && (
        <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Grid>
        <Button 
              onClick={generateEmail} 
              disabled={!validation.values.firstName || !validation.values.lastName}
              variant="outlined" 
              color="primary"
              
            >
              Generate Email
            </Button>
            </Grid>
            <Grid ml={2}> 
          <Button type="submit" variant="outlined" color="success" disabled={!agent || isLoading||!validation.values.email}>
          {isLoading ? <CircularProgress size={24} /> : "Add SubAgent"}
          </Button>
          </Grid>
        </Grid>
        )}
      </form>
      <ToastContainer />
    </>
  );
}

export default AddAgentModal;
