const env=process.env.REACT_APP_API_URL;
export const getWalletConfig = async (user_id) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
    var URL = `${env}/wallet-config/${user_id}`
    const response = await fetch(URL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};

export const updateWalletConfig = async (updatedData,user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/wallet-config/${user_id}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error updating WalletConfig:', error);
      throw error;
    }
  };

  export const getWalletList = async (user_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/wallets/balance/${user_id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };

  export const getAgentBrokerages = async (agent_id,subagent_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/agentBrokerageBySubagentId?agent_id=${agent_id}&subagent_id=${subagent_id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };