
import { Grid} from '@mui/material';
// project import
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return (
    <>
    <Grid sx={{display:"flex"}}>
      <Profile />
      </Grid>
    </>
  );
};

export default HeaderContent;
