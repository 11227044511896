import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, TextField, Button, FormHelperText, Autocomplete,CircularProgress,Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { copyAgentConfig, createUser,getAllAgents, getAllSubAgents } from '../../Services/UserService';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function AddAgentModal(props) {
  const [agentId, setAgentId] = useState();
  const [role, setRole] = useState();
  const [options, setOptions] = useState();
  const[newAgent,setNewAgent]=useState({});
  const [apidata, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyConfigsChecked, setIsCopyConfigsChecked] = useState(false);
  const[userId,setUserId]=useState();
  const[password,setPassword]=useState();
  const[show,setShow]=useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [selected, setSelected] = useState();
  const [search,setSearch]=useState();
  const [id,setId]=useState();
  const [isLoading2, setIsLoading2] = useState(false);
  const [users, setUsers] = useState();
  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole);
  }, []);
  const { modalHandleCallback } = props;

  useEffect(() => {
    const filteredData = apidata.filter(item => item.user_id.startsWith('AG'));
    setOptions(filteredData.map(item => `${item.user_id} -> ${item.email}`));
  }, [apidata]);


  useEffect(() => {
    if(agentId && role){
    fetchAgents('true', agentId,search);
    }
  }, [agentId,role,search]);


  const fetchAgents = async (bool, userIds, search_query) => {
    try {
      setIsLoading(true); // Set loading state to true when fetching agents
      let agentsPromise, subAgentsPromise;
      if (role === 'AG') {
        subAgentsPromise = getAllSubAgents(bool, userIds, 1, 10, search_query);
        agentsPromise = Promise.resolve([]);
      } else if (role === 'SA') {
        subAgentsPromise = Promise.resolve([]);
        agentsPromise = Promise.resolve([]);
      } else {
        agentsPromise = getAllAgents(bool, userIds, 1, 10, search_query);
        subAgentsPromise = getAllSubAgents(bool, userIds, 1, 10, search_query);
      }
  
      const [agents, subAgents] = await Promise.all([agentsPromise, subAgentsPromise]);
      let userOptions = [];
      let subAgentOptions = [];
  
      if (role === 'AG') {
        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      } else if (role === "AU") {
        userOptions = agents?.rows.map((user) => ({
          label: user.email,
          id: user.user_id
        }));
  
        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      }
  
      let finalOptions = [];
      if (role === 'AG') {
        finalOptions = [
          ...subAgentOptions.filter(option => option.label !== userId), // Filter out the user ID
          {
            label: sessionStorage.getItem('email'),
            id: agentId // Assuming 'userId' is stored in sessionStorage
          }
        ];
      }
      else if (role === 'SA') {
        finalOptions = [
          {
            label: sessionStorage.getItem('email'),
            id: agentId // Assuming 'userId' is stored in sessionStorage
          }
        ];
      } else {
        finalOptions = [
          ...userOptions.filter(option => option.label !== userId), // Filter out the user ID
          ...subAgentOptions.filter(option => option.label !== userId) // Filter out the user ID
        ];
      }
  
      // Exclude the final self-reference if necessary
      finalOptions = finalOptions.filter(option => option.label !== userId);
  
      setOptions(finalOptions);
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching agents
    }
  };

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
   
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter the First Name'),
      lastName: Yup.string().required('Please Enter the Last Name'),
      email: Yup.string()
        .required('Please Enter the Email')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
        const newAgent={
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          user_role: 'AG',
          oversee_user: agentId
        };
        setIsLoading(true);
        createUser(newAgent).then((response)=>{
          toast.success('Agent created successfully', { autoClose: 2000 });
          setShow(true);
          setId(response.user_id);
          setUserId(response.email);
          setPassword(response.password);
        }).catch((error)=>{
          let errorMessage = 'Failed to create Agent';
          try{
            const errorDetail = JSON.parse(error.message);
            if (errorDetail && errorDetail.detail) {
                errorMessage = errorDetail.detail;
                // console.log(errorMessage); 
            }
          } catch (parseError) {
               console.error('Error parsing JSON response:', parseError);
             }finally{
              setSubmitting(false);
              setIsLoading(false);
             }
             toast.error(errorMessage, { autoClose: 2000 });
             setSubmitting(false);
             setIsLoading(false);
        })
        
      }
  });
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!', { autoClose: 2000 });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy to clipboard.', { autoClose: 2000 });
    });
  };

  const generateEmail = () => {
    if(validation.values.firstName && validation.values.lastName && agentId) {
      const email = `${validation.values.firstName}${validation.values.lastName}.agent@gmail.com`.toLowerCase();
      validation.setFieldValue('email', email);
    }
  };
  const handleCheckboxChange = (event) => {
    setIsCopyConfigsChecked(event.target.checked);
  };
  const handleSelect2 = (event, value) => {
    setAutocompleteValue(value);
    setSelected(value ? value.id : '');
  };
  const handleInput2 = (event, newValue) => {
    setSearch(newValue);
  };
  const copy_config = () => {
    var config_data={
      new_user_id:id,
      old_user_id:selected,
    }
    setIsLoading2(true);
    copyAgentConfig(config_data).then((response) => {
      toast.success('AgentConfig copied successfully', { autoClose: 2000 });
    }).catch((error) => {
      toast.error('Failed to copy AgentConfig', { autoClose: 2000 });
    }).finally(() => {
      setIsLoading2(false);
    });
  }
  return (
    <>
      <form onSubmit={validation.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              type="text"
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={validation.values.firstName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
            />
            {validation.errors.firstName && validation.touched.firstName ? (
              <FormHelperText error>{validation.errors.firstName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={validation.values.lastName || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
            />
            {validation.errors.lastName && validation.touched.lastName ? (
              <FormHelperText error>{validation.errors.lastName}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <TextField
              label="Email"
              varian="outlined"
              fullWidth
              name="email"
              value={validation.values.email || ''}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={validation.errors.email && validation.touched.email ? true : false}
            />
            {validation.errors.email && validation.touched.email ? <FormHelperText error>{validation.errors.email}</FormHelperText> : null}
          </Grid>
          {show && (
        <>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCopyConfigsChecked}
                  onChange={handleCheckboxChange}
                  name="copyConfigs"
                  color="primary"
                />
              }
              label="Copy config from another Agent/SubAgent"
            />
            {isCopyConfigsChecked && (
              <>
                <Autocomplete
                  disablePortal
                  value={autocompleteValue}
                  id="combo-box-demo"
                  options={options}
                  getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
                  sx={{ width: '100%' }}
                  onChange={handleSelect2}
                  onInputChange={handleInput2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agent/SubAgent ID"
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '40px', // Adjust the height as needed
                          '& input': {
                            fontSize: '0.75rem' // Adjust the font size as needed
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '4px' // Optional: Adjust border radius if needed
                          }
                        }
                      }}
                      />
            )}
                />
                <Box mt={2}>
                <Button
                  variant="outlined"
                  color="success"
                  disabled={isLoading2 || !autocompleteValue}
                  onClick={copy_config}
                  
                >
                  {isLoading2 ? <CircularProgress size={24} /> : "Copy Config"}
                </Button>
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} ml={2} p={2} bgcolor="#f0f0f0" borderRadius="borderRadius">
              <Box display="flex" flexDirection="row">
                <Typography variant="h6" mt={0.5} mr={2}>User Credentials</Typography>
                <IconButton onClick={() => copyToClipboard(`Email: ${userId}\nPassword: ${password}`)} aria-label="copy">
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              <Typography variant="body1">Email:&nbsp;&nbsp;{userId}</Typography>
              <Typography variant="body1">Password:&nbsp;&nbsp;{password}</Typography>
            </Box>
          </Grid>
        </>
      )}
        </Grid>
        {!show && (
        <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Grid>
        <Button 
              onClick={generateEmail} 
              disabled={!validation.values.firstName || !validation.values.lastName }
              variant="outlined" 
              color="primary"
            >
              Generate Email
            </Button>
            </Grid>
            <Grid ml={2}> 
          <Button type="submit" variant="outlined" color="success" disabled={ isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Add Agent"}
          </Button>
          </Grid>
        </Grid>
        )}
      </form>
      <ToastContainer />
    </>
  );
}

export default AddAgentModal;
