import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  FormControlLabel,
  Card,
  Tabs,
  Tab,
  Autocomplete,
  DialogTitle
} from '@mui/material';
import { DataGrid,GridToolbar,GridOverlay } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { getAllAgents, getAllSubAgents, getAllUsers, getUsersWithKeyword } from '../../Services/UserService';
import { getTranscation } from '../../Services/ReportsService';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { jwtDecode } from "jwt-decode";
import TablePagination from '@mui/material/TablePagination';
import { formatValueIndian } from '../../utils/Formatvalue';
import { formatAndMultiplyValueAssetItem } from '../../utils/formatAndMultiplyValue';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {downloadTransactionReport } from '../../Services/excel_reports_service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AgentTransactions() {
    const [data, setData] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [transaction, SetTransaction] = useState([]);
    const [trade, SetTrade] = useState([]);
    const [logs, SetLogs] = useState([]);
    const [options, setOptions] = useState();
    const [role, setRole] = useState();
    const [selected, setSelected] = useState();
    const[exchange,setExchange]=useState()
    const [activeTab, setActiveTab] = useState('tradeHistory');
    const [start, setStart] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [end, setEnd] = useState(null);
    const [agentId, setAgentId] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const[page,setPage]=useState(0);
    const [pageSize,setPageSize]=useState(10)
    const[count,setCount]=useState(0);
    const [isLoading, setIsLoading] = useState(false);

    dayjs.extend(customParseFormat);
  const today = dayjs();
 

  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole);
  },[])

  useEffect(() => {
    if(agentId && role){
    fetchAgents('true', agentId,searchKeyword);
    }
  }, [agentId,role,searchKeyword]);

  const fetchAgents = async (bool, userId, search_query) => {
    try {
      setIsLoading(true); // Set loading state to true when fetching agents
      let agentsPromise, subAgentsPromise;
      if (role === 'AG') {
        subAgentsPromise = getAllSubAgents(bool, userId, 1, 10, search_query);
        agentsPromise = Promise.resolve([]);
      } else if (role === 'SA') {
        subAgentsPromise = Promise.resolve([]);
        agentsPromise = Promise.resolve([]);
      } else {
        agentsPromise = getAllAgents(bool, userId, 1, 10, search_query);
        subAgentsPromise = getAllSubAgents(bool, userId, 1, 10, search_query);
      }

      const [agents, subAgents] = await Promise.all([agentsPromise, subAgentsPromise]);
      let userOptions = {};
      let subAgentOptions = {};

      if (role === 'AG') {
        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      } else if (role === "AU") {
        userOptions = agents?.rows.map((user) => ({
          label: user.email,
          id: user.user_id
        }));

        subAgentOptions = subAgents?.rows.map((subAgent) => ({
          label: subAgent.email,
          id: subAgent.user_id
        }));
      }

      let finalOptions = [];
      if (role === 'AG') {
        finalOptions = [
          ...subAgentOptions,
          {
            label: sessionStorage.getItem('email'),
            id: agentId
          }
        ];
      }
      else if (role === 'SA') {
        finalOptions = [
          {
            label: sessionStorage.getItem('email'),
            id: agentId
          }
        ];
      } else {
        finalOptions = [
          ...userOptions,
          ...subAgentOptions
        ];
      }

      setOptions(finalOptions);
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    } finally {
      setIsLoading(false); // Set loading state to false after fetching agents
    }
  };

  const handleSelect = (event, value) => {
    setSelected(value ? value.id : '');
    if (!value) {
      setStartDate(null);    
      setEndDate(null);      
      setStart(null);        
      SetLogs([]);           
      SetTrade([]);          
      SetTransaction([]); 
    }
  };
  const handleInputChange = (event, newValue) => {
    setSearchKeyword(newValue);
  };
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
    const adjustedEndDate = newValue ? dayjs(newValue).add(1, 'day').format('YYYY-MM-DD') : null;
    setEnd(adjustedEndDate);
  };
  const transactionData = async (id,exchange,start,end, page, page_size) => {
    try {
      setIsLoading(true)
      const data = await getTranscation(id,exchange,start,end, page+1, page_size);
      SetTransaction(data?.data);
      setCount(data?.count);
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching transactions: ', error);
    }finally{
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    if (selected !== undefined) {
        // No need to reset page and pageSize when selected changes
        if(startDate !== undefined && end !== undefined ){
           
            transactionData(selected,exchange, startDate, end, page, pageSize);
        }
    }
}, [startDate, end, selected, activeTab, page, pageSize,exchange]);

  const formatCurrency = (value, exchange) => {
    console.log(value, exchange)
    // Default currency symbol
    let currencySymbol = '';
  
    // Check if exchange is defined and set the currency symbol accordingly
    if (exchange === "NASDAQ" || exchange === "CRYPTO") {
      currencySymbol = '$';
    } else if (exchange === 'NSE'|| exchange === "MCX") {
      currencySymbol = '₹';
    }
  
    const fValue =formatValueIndian(value?value:0)
    // Return formatted currency with the determined symbol
    return `${currencySymbol} ${fValue}`;
  };

  const dateOptions = {
    month: 'short',
    day: 'numeric', 
    year: 'numeric', 
    hour: 'numeric', 
    minute: '2-digit', 
    second: '2-digit', 
   // timeZoneName: 'short'
  };
  const transcationColumns = [
    { field: 'id', headerName: 'ID', flex:1},
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params) => formatAndMultiplyValueAssetItem(params.value || 0, params.row && params.row.exchange),
    },
   
    { field: 'currency', headerName: 'Currency',  flex:1 },
    { field: 'exchange', headerName: 'Wallet',  flex:1 },
    {
      field: 'is_deposit',
      headerName: 'Type',
      flex:1,
      renderCell: (params) => {
        return params.row.is_deposit ? 'Deposit' : 'Withdraw';
      }
    },
    { field: 'updated_by', headerName: 'Updated_by',  flex:1 },
    {
      field: 'created_at',
      headerName: 'Transaction Date',
      flex:1,
      renderCell: (params) => {
       
        return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(params.value));
       ;
      },
    },
  ];

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSelect2 = (event, value) => {
    setExchange(value ? value : '');
  
  };
  const download = async () => {
    if (startDate !== undefined && end !== undefined) {
      try {
       
          await downloadTransactionReport(selected, exchange, startDate, end, page, pageSize);
          toast.success("Transaction logs downloaded successfully!");
        
      } catch (error) {
        console.error(error);
        const errorMessages = {
          transcationLogs: "Failed to download transaction logs."
        };
        toast.error(errorMessages["transcationLogs"] || "Download failed.");
      }
    } 
  };
  return (
  <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'row' ,justifyContent: 'space-between'}} mt={0}>
        <Grid mt={1}>
        <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
              onChange={handleSelect}
              sx={{ width: 350 }}
              onInputChange={handleInputChange}
              renderInput={(params) => <TextField {...params} label="Select Agent / SubAgent"   size="small" variant="outlined"  sx={{
                '& .MuiInputBase-root': {
                  height: '40px', // Adjust the height as needed
                  '& input': {
                    fontSize: '0.875rem' // Adjust the font size as needed
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '4px' // Optional: Adjust border radius if needed
                  }
                }
              }} />}
            />
        </Grid>
        {selected&&(
        <Grid mt={1}>
        <Button variant="outlined" onClick={()=>download()} startIcon={<DownloadOutlinedIcon />}>
        Download
        </Button>
        </Grid>
        )}
        {selected&&(
          <>
           <Grid mt={1} ml={2}>
           <Autocomplete
           disablePortal
           id="combo-box-demo"
           options={["NSE","NASDAQ","MCX","CRYPTO"]}
           sx={{ width: 150 }}
           onChange={handleSelect2}
           value={exchange}
           renderInput={(params) => (
             <TextField
               {...params}
               label="Exchange"
               sx={{
                 '& .MuiInputBase-root': {
                   height: '40px', // Adjust the height as needed
                   '& input': {
                     fontSize: '0.875rem' // Adjust the font size as needed
                   },
                   '& .MuiOutlinedInput-notchedOutline': {
                     borderRadius: '4px' // Optional: Adjust border radius if needed
                   }
                 }
               }}
             />
           )}
         />
         </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} mt={0} ml={2}>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DemoItem>
                  <DatePicker
                    label="From"
                    slotProps={{
                      field: { clearable: true },
                    }}
                    sx={{ width: 200 }}
                    value={startDate}
                    maxDate={today}
                    onChange={(newValue) => (setStart(newValue),handleStartDateChange(newValue))}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid ml={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DemoItem>
                  <DatePicker
                    label="To"
                    sx={{ width: 200 }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    value={endDate}
                    minDate={startDate ? dayjs(startDate).add(1, 'day') : null}
                    maxDate={today}
                    onChange={(newValue) => handleEndDateChange(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        </>
        )}
      </Grid>
 <DataGrid
                 rows={transaction}
                 columns={transcationColumns}
                 sortModel={[
                   {
                     field: 'created_at',
                     sort: 'desc'
                   }
                 ]}
                 getRowId={(data) => data.id}
                 autoHeight={transaction.length===0}
                 sx={{
                   maxWidth: '97%',
                   maxHeight: transaction.length > 0 ? '90vh' : 'auto',
                   minHeight: transaction.length ? '400px' : '100px', // Conditionally setting minHeight
                 }}
                 loading={isLoading}
                 hideFooterPagination
        hideFooter='true'
     />
     <Box display={"flex"} justifyContent={"flex-end"}>
     <TablePagination
   component="div"
   count={count}
   page={page}
   onPageChange={handleChange}
   rowsPerPage={pageSize}
   onRowsPerPageChange={handleChangeRowsPerPage}
 />            </Box>
<ToastContainer/>
</Grid>
  )
}

export default AgentTransactions