const env=process.env.REACT_APP_API_URL;
export const getApprovalTranscations = async (user_id, tab, page, pageSize, searchKeyword) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }
    
    // Construct the URL conditionally based on pagination and search
    let url = `${env}/alltransactions/${user_id}?status=${tab}&page=${page}&page_size=${pageSize}`;
    if (searchKeyword) {
      url += `&search_keyword=${encodeURIComponent(searchKeyword)}`;
    }

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};



  export const getTranscationsCounts = async (oversee_id) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      const response = await fetch(`${env}/transactions/status-count/${oversee_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching counts: ', error);
      throw error;
    }
  };

  export const updateTrans = async (id, status, user_id) => {
    try {
        const token = sessionStorage.getItem('authToken');
        if (!token) {
            throw new Error('Authentication token not found in session storage');
        }
        const response = await fetch(`${env}/transactions/update_status/${id}?status=${status}&user=${user_id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            // If response is not ok, parse the error details and throw them
            const errorData = await response.json(); // Assuming the server returns a JSON with error details
            throw new Error(errorData.detail || `Network response was not ok. Status: ${response.status}`);
        }

        return await response.json(); // Return the response data if everything went well
    } catch (error) {
        console.error('Error updating transaction:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};
