import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Card,
  Tabs,
  Tab,
  Typography,
  CardContent,
  Autocomplete,
  DialogTitle,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar, GridOverlay } from '@mui/x-data-grid';
import { getAllUsers, getUsersWithKeyword } from '../../Services/UserService';
import { getTranscation, getTrades, getLogs } from '../../Services/ReportsService';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { jwtDecode } from 'jwt-decode';
import TablePagination from '@mui/material/TablePagination';
import { formatValueIndian } from '../../utils/Formatvalue';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { downloadLoginReport, downloadTradesReport, downloadTransactionReport } from '../../Services/excel_reports_service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Reports() {
  const [apiData, setApiData] = useState([]);
  const [transaction, SetTransaction] = useState([]);
  const [trade, SetTrade] = useState([]);
  const [logs, SetLogs] = useState([]);
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [activeTab, setActiveTab] = useState('tradeHistory');
  const [start, setStart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [end, setEnd] = useState(null);
  const [ids, setId] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [lifeTime, setlifeTime] = useState({});
  const [profits, setProfits] = useState({});
  const [exchange, setExchange] = useState();
  const [loading, setloading] = useState(false);
  dayjs.extend(customParseFormat);
  const today = dayjs();
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setExchange('');
  };

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
  }, []);

  const fetchUsers = async (user_id, keyword) => {
    try {
      const data = await await getAllUsers('true', user_id, 1, 10, keyword);
      setApiData(data?.rows);
    } catch (error) {
      console.error('Error fetching users: ', error);
    }
  };
  const transactionData = async (id, exchange, start, end, page, page_size) => {
    try {
      setloading(true);
      const data = await getTranscation(id, exchange, start, end, page + 1, page_size);
      SetTransaction(data?.data);
      setCount(data?.count);
      setlifeTime(data?.credit_debit_by_exchange);
      setProfits(data?.profits_by_exchange);
    } catch (error) {
      setloading(false);
      console.error('Error fetching transactions: ', error);
    } finally {
      setloading(false);
    }
  };

  const tradeData = async (id, exchange, start, end, page, page_size) => {
    try {
      setloading(true);
      const data = await getTrades(id, exchange, start, end, page + 1, page_size);
      SetTrade(data?.data);
      setCount(data?.count);
    } catch (error) {
      console.error('Error fetching Trades: ', error);
    } finally {
      setloading(false);
    }
  };
  const logData = async (log, start, end, page, page_size) => {
    try {
      setloading(true);
      const data = await getLogs(log, start, end, page + 1, page_size);
      SetLogs(data?.data);
      setCount(data?.count);
    } catch (error) {
      setloading(false);
      console.error('Error fetching Logs: ', error);
    } finally {
      setloading(false);
    }
  };
  const processedData = logs.map((item) => ({
    ...item,
    email: item.email || item.user_id // If email is empty, user_id will be used
  }));
  useEffect(() => {
    if (ids) {
      fetchUsers(ids, searchKeyword);
    }
  }, [ids, searchKeyword]);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue ? dayjs(newValue).format('YYYY-MM-DD') : null);
    const adjustedEndDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : null;
    setEnd(adjustedEndDate);
  };

  useEffect(() => {
    if (selected !== undefined) {
      if (startDate !== undefined && end !== undefined && activeTab === 'tradeHistory') {
        tradeData(selected, exchange, startDate, end, page, pageSize);
      } else if (startDate !== undefined && end !== undefined && activeTab === 'userLoginHistory') {
        logData(selected, startDate, end, page, pageSize);
      } else if (startDate !== undefined && end !== undefined && activeTab === 'transcationLogs') {
        transactionData(selected, exchange, startDate, end, page, pageSize);
      }
    }
  }, [startDate, end, selected, activeTab, page, pageSize, exchange]);

  const download = async () => {
    if (startDate !== undefined && end !== undefined) {
      try {
        if (activeTab === 'tradeHistory') {
          await downloadTradesReport(selected, startDate, end, exchange);
          toast.success('Trade history downloaded successfully!');
        } else if (activeTab === 'userLoginHistory') {
          await downloadLoginReport(selected, startDate, end, page, pageSize);
          toast.success('User login history downloaded successfully!');
        } else if (activeTab === 'transcationLogs') {
          await downloadTransactionReport(selected, exchange, startDate, end, page, pageSize);
          toast.success('Transaction logs downloaded successfully!');
        }
      } catch (error) {
        console.error(error);
        const errorMessages = {
          tradeHistory: 'Failed to download trade history.',
          userLoginHistory: 'Failed to download user login history.',
          transcationLogs: 'Failed to download transaction logs.'
        };
        toast.error(errorMessages[activeTab] || 'Download failed.');
      }
    }
  };
  useEffect(() => {
    // Reset page and pageSize only when activeTab changes
    if (activeTab !== undefined) {
      setPage(0);
      setPageSize(10);
      setExchange();
    }
  }, [activeTab]);

  useEffect(() => {
    setOptions(
      apiData.map((user) => ({
        label: user.email + ' (' + user.user_id + ')',
        id: user.user_id
      }))
    );
  }, [apiData]);

  const formatCurrency = (value, exchange) => {
    let currencySymbol = '';
    // Check if exchange is defined and set the currency symbol accordingly
    if (exchange === 'NASDAQ' || exchange === 'CRYPTO') {
      currencySymbol = '$';
    } else if (exchange === 'NSE' || exchange === 'MCX') {
      currencySymbol = '₹';
    }
    const fValue = formatValueIndian(value ? value : 0);
    // Return formatted currency with the determined symbol
    return `${currencySymbol} ${fValue}`;
  };
  const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
    // timeZoneName: 'short'
  };
  // Filter data based on searchQuery
  //const filteredData = data.filter((item) => item.firstName.includes(searchQuery));
  const tradeColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'exchange', headerName: 'Type', flex: 1 },
    { field: 'instrument_name', headerName: 'Name', flex: 1 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value || 0, params.row && params.row?.exchange)
    },
    { field: 'quantity', headerName: 'Quantity', flex: 1 },
    {
      field: 'charges',
      headerName: 'Charges',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value || 0, params.row && params.row?.exchange)
    },
    {
      field: 'total',
      headerName: 'Total',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value || 0, params.row && params.row.exchange)
    },
    {
      field: 'transaction_type',
      headerName: 'Buy/Sell',
      flex: 1,
      valueGetter: (params) => {
        return params.row.buy ? 'BUY' : 'SELL';
      }
    },
    {
      field: 'created_at',
      headerName: 'Transaction Date',
      width: 180,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(params.value));
      }
    }
  ];
  const transcationColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params) => formatCurrency(params.value || 0, params.row && params.row.exchange)
    },

    { field: 'currency', headerName: 'Currency', flex: 1 },
    { field: 'exchange', headerName: 'Wallet', flex: 1 },
    {
      field: 'is_deposit',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) => {
        return params.row.is_deposit ? 'Deposit' : 'Withdraw';
      }
    },
    { field: 'updated_by', headerName: 'Updated_by', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Transaction Date',
      width: 180,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(params.value));
      }
    }
  ];
  const logsColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'user_id', headerName: 'User ID', flex: 1 },
    { field: 'email', headerName: 'Email/User_Id', flex: 1 },
    { field: 'host', headerName: 'Host', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Login TimeStamp',
      width: 180,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(params.value));
      }
    }
  ];
  const handleSelect = (event, value) => {
    setSelected(value ? value.id : '');
    if (!value) {
      setStartDate(null);
      setEndDate(null);
      setStart(null);
      SetLogs([]);
      SetTrade([]);
      SetTransaction([]);
    }
  };
  const handleSelect2 = (event, value) => {
    setExchange(value ? value : '');
  };
  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  function FinancialSummaryCards({ data, data2 }) {
    const getCurrencySymbol = (exchange) => {
      return exchange === 'MCX' || exchange === 'NSE' ? '₹' : '$';
    };
    const createAccordionContent = (type) => {
      return Object.entries(data).map(([exchange, values]) => {
        const value = values[type] ?? 0; // Treat null values as zero
        const currencySymbol = getCurrencySymbol(exchange);
        return (
          <Box key={exchange} display="flex" justifyContent="space-between">
            <Typography>{exchange}</Typography>
            <Typography>
              {currencySymbol}
              {formatValueIndian(value)}
            </Typography>
          </Box>
        );
      });
    };

    // Calculate and format profit for each exchange
    const createProfitContent = (type) => {
      return Object.entries(data2).map(([exchange, values]) => {
        const value = values[type] ?? 0;
        const currencySymbol = getCurrencySymbol(exchange);
        return (
          <Box key={exchange} display="flex" justifyContent="space-between">
            <Typography>{exchange}</Typography>
            <Typography>
              {currencySymbol}
              {formatValueIndian(value)}
            </Typography>
          </Box>
        );
      });
    };

    return (
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Lifetime Credit</Typography>
            </AccordionSummary>
            <AccordionDetails>{createAccordionContent('lifetime_credit')}</AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Lifetime Debit</Typography>
            </AccordionSummary>
            <AccordionDetails>{createAccordionContent('lifetime_debit')}</AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Lifetime Profit</Typography>
            </AccordionSummary>
            <AccordionDetails>{createProfitContent('lifetime_profit')}</AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mt={0}>
        <Grid mt={1}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{ width: 350 }}
            onChange={handleSelect}
            onInputChange={(event, newInputValue) => {
              setSearchKeyword(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User ID"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '40px', // Adjust the height as needed
                    '& input': {
                      fontSize: '0.875rem' // Adjust the font size as needed
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '4px' // Optional: Adjust border radius if needed
                    }
                  }
                }}
              />
            )}
          />
        </Grid>
        {selected && (
          <Grid mt={1}>
            <Button variant="outlined" onClick={() => download()} startIcon={<DownloadOutlinedIcon />}>
              Download
            </Button>
          </Grid>
        )}
        {selected && activeTab !== 'userLoginHistory' && (
          <Grid mt={1}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={['NSE', 'NASDAQ', 'MCX', 'CRYPTO']}
              sx={{ width: 150 }}
              onChange={handleSelect2}
              value={exchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Exchange"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px', // Adjust the height as needed
                      '& input': {
                        fontSize: '0.875rem' // Adjust the font size as needed
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '4px' // Optional: Adjust border radius if needed
                      }
                    }
                  }}
                />
              )}
            />
          </Grid>
        )}
        {selected && (
          <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} mt={0}>
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateRangePicker']}>
                  <DemoItem>
                    <DatePicker
                      label="From"
                      slotProps={{
                        field: { clearable: true }
                      }}
                      sx={{ width: 200 }}
                      value={startDate}
                      maxDate={today}
                      onChange={(newValue) => (setStart(newValue), handleStartDateChange(newValue))}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid ml={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateRangePicker']}>
                  <DemoItem>
                    <DatePicker
                      label="To"
                      sx={{ width: 200 }}
                      slotProps={{
                        field: { clearable: true }
                      }}
                      value={endDate}
                      minDate={dayjs(startDate)}
                      maxDate={today}
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Grid>
      {activeTab === 'transcationLogs' && (
        <Grid>
          <FinancialSummaryCards data={lifeTime} data2={profits} />
        </Grid>
      )}
      <Grid mt={2}>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={<span>Trade History</span>} value="tradeHistory" />
              <Tab label={<span>User Login History</span>} value="userLoginHistory" />
              <Tab label={<span>Transaction Logs</span>} value="transcationLogs" />
            </Tabs>
          </Box>
        </Card>
      </Grid>
      <Grid>
        {activeTab === 'tradeHistory' && (
          <>
            <DataGrid
              rows={trade}
              columns={tradeColumns}
              sortModel={[
                {
                  field: 'created_at',
                  sort: 'desc'
                }
              ]}
              getRowId={(data) => data.id}
              autoHeight={trade.length === 0}
              sx={{
                maxWidth: '98%',
                maxHeight: trade.length > 0 ? '90vh' : 'auto',
                minHeight: trade.length ? '400px' : '100px' // Conditionally setting minHeight
              }}
              hideFooterPagination
              hideFooter="true"
              loading={loading}
            />
            <Box display={'flex'} justifyContent={'flex-end'}>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{' '}
            </Box>
          </>
        )}
        {activeTab === 'userLoginHistory' && (
          <>
            <DataGrid
              rows={processedData}
              columns={logsColumns}
              sortModel={[
                {
                  field: 'created_at',
                  sort: 'desc'
                }
              ]}
              getRowId={(data) => data.id}
              autoHeight={processedData.length === 0}
              sx={{
                maxWidth: '97%',
                maxHeight: processedData.length > 0 ? '90vh' : 'auto',
                minHeight: processedData.length ? '400px' : '100px' // Conditionally setting minHeight
              }}
              hideFooterPagination
              hideFooter="true"
              loading={loading}
            />
            <Box display={'flex'} justifyContent={'flex-end'}>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{' '}
            </Box>
          </>
        )}
        {activeTab === 'transcationLogs' && (
          <>
            <DataGrid
              rows={transaction}
              columns={transcationColumns}
              sortModel={[
                {
                  field: 'created_at',
                  sort: 'desc'
                }
              ]}
              getRowId={(data) => data.id}
              autoHeight={transaction.length === 0}
              sx={{
                maxWidth: '97%',
                maxHeight: transaction.length > 0 ? '90vh' : 'auto',
                minHeight: transaction.length ? '400px' : '100px' // Conditionally setting minHeight
              }}
              hideFooterPagination
              hideFooter="true"
              loading={loading}
            />
            <Box display={'flex'} justifyContent={'flex-end'}>
              <TablePagination
                component="div"
                count={count}
                page={page}
                onPageChange={handleChange}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{' '}
            </Box>
          </>
        )}
      </Grid>
      <ToastContainer />
    </Grid>
  );
}

export default Reports;
