const env=process.env.REACT_APP_API_URL;

export const getTranscation = async (user_id,exchange, start_date, end_date, page, page_size) => {
  try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
          throw new Error('Authentication token not found in session storage');
      }
      // Construct the URL conditionally based on start_date, end_date, page, and page_size
      let url = `${env}/transactions/${user_id}?page=${page}&page_size=${page_size}`;
      if (start_date && start_date !== 'undefined') {
          url += `&start_date=${encodeURIComponent(start_date)}`;
      }
      if (end_date && end_date !== 'undefined') {
          url += `&end_date=${encodeURIComponent(end_date)}`;
      }
      if (exchange && exchange !== 'undefined') {
        url += `&exchange=${encodeURIComponent(exchange)}`;
    }

      const response = await fetch(url, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      return await response.json();
  } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
  }
};


  export const getTrades = async (user_id,exchange, start_date, end_date, page, page_size) => {
    try {
        const token = sessionStorage.getItem('authToken');
        if (!token) {
            throw new Error('Authentication token not found in session storage');
        }
        let url = `${env}/trades/${user_id}?page=${page}&page_size=${page_size}`;
        if (start_date && start_date !== 'undefined') {
          url += `&start_date=${encodeURIComponent(start_date)}`;
      }
      if (end_date && end_date !== 'undefined') {
          url += `&end_date=${encodeURIComponent(end_date)}`;
      }
      if (exchange && exchange !== 'undefined') {
        url += `&exchange=${encodeURIComponent(exchange)}`;
    }

      const response = await fetch(url, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data: ', error);
        throw error;
    }
};



export const getLogs = async (user_id, start_date, end_date, page, page_size) => {
  try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
          throw new Error('Authentication token not found in session storage');
      }
      let url = `${env}/user/auth/logs/${user_id}?page=${page}&page_size=${page_size}`;
      if (start_date && start_date !== 'undefined') {
        url += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date && end_date !== 'undefined') {
        url += `&end_date=${encodeURIComponent(end_date)}`;
    }

    const response = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      return await response.json();
  } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
  }
};

export const getPositions = async (user_id, page , pageSize , searchKeyword = null) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token not found in session storage');
    }

    // Construct the URL conditionally
    let url = `${env}/getPositionsSummary/${user_id}?page=${page}&page_size=${pageSize}`;
    if (searchKeyword) {
      url += `&search_keyword=${encodeURIComponent(searchKeyword)}`;
    }
    
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};


export const getPositionsbyAgent = async (user_id,instrument_id,page , pageSize) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      // Construct the URL conditionally
      let url = `${env}/getPositionsSummaryByInstrument/${user_id}/${instrument_id}?page=${page}&page_size=${pageSize}`;
      // if (searchKeyword) {
      //   url += `&search_keyword=${encodeURIComponent(searchKeyword)}`;
      // }
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };


  export const getPositionsbyUser = async (user_id,instrument_id,page,pageSize) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
  
      // Construct the URL conditionally
      let url = `${env}/getPositionsSummaryByInstrumentUser/${user_id}/${instrument_id}?page=${page}&page_size=${pageSize}`;
      
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };


  export const getAgentBrokerages = async (agent_id,start_date,end_date,page,pageSize) => {
    try {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        throw new Error('Authentication token not found in session storage');
      }
      let url = `${env}/brokerageReport?agent_id=${agent_id}&page=${page}&page_size=${pageSize}`
      if (start_date && start_date !== 'undefined') {
        url += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date && end_date !== 'undefined') {
        url += `&end_date=${encodeURIComponent(end_date)}`;
    }
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  };