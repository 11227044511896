export const formatValue = (value) => {
    return parseFloat(value)
      .toFixed(2)
      .toLocaleString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  export const formatValueIndian = (value) => {
    const number = parseFloat(value).toFixed(8);
    return new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 8, 
      style: 'decimal' 
    }).format(number);
  };