import React, { useEffect,useState } from 'react';
import { Box, Grid, IconButton, TextField, Button, FormHelperText,Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createUser,getAllAgents, updateUser } from '../../Services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
function SupportEditModal(props) {
    const {data,openEdit,modalHandleCallback}=props;
const[agentId,setAgentId]=useState();
const[role,setRole]=useState();
// const[newUser,setNewUser]=useState({});
const [selectedUserId, setSelectedUserId] = useState('');
const[options,setOptions]=useState([]);
  useEffect(()=>{
    fetchAgents("true",agentId);
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setAgentId(decodedToken.UserID);
    setRole(decodedToken.UserRole)
  },[])
  const fetchAgents = async (bool,userId) => {
    try {
      const data = await getAllAgents(bool,userId);
      setOptions(data.map((user) => ({
        label: user.email,
        id: user.user_id
      })));
    } catch (error) {
      console.error('Error fetching Agents: ', error);
    }
  };

  useEffect(() => {
    if (openEdit) {
      validation.setFieldValue('firstName', data?.first_name);
      validation.setFieldValue('lastName', data?.last_name);
      validation.setFieldValue('email', data?.email);
    }
  }, []);
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter the First Name'),
      lastName: Yup.string().required('Please Enter the Last Name'),
      email: Yup.string()
        .required('Please Enter the Email')
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email format')
    }),
    onSubmit: (values) => {
        updateUser({
            user_id: data?.user_id,
            user_role: 'SU',
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email
          })
          .then((response) => {
            toast.success('Support User updated successfully', {
              autoClose: 2000
            });
            validation.resetForm({ values: defaultValues });
            setTimeout(() => {
              modalHandleCallback();
            }, 1000);
          })
          .catch((error) => {
            toast.error('Failed to update Support User', {
              autoClose: 2000
            });
          });
        }
  });

  const handleSelection = (event, value) => {
    setSelectedUserId(value ? value.id : '');
  };

  return (
    <>
    <form onSubmit={validation.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            type="text"
            label="First Name"
            variant="outlined"
            fullWidth
            name="firstName"
            value={validation.values.firstName || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.firstName && validation.touched.firstName ? true : false}
          />
          {validation.errors.firstName && validation.touched.firstName ? (
            <FormHelperText error>{validation.errors.firstName}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            name="lastName"
            value={validation.values.lastName || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.lastName && validation.touched.lastName ? true : false}
          />
          {validation.errors.lastName && validation.touched.lastName ? (
            <FormHelperText error>{validation.errors.lastName}</FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} mt={2}>
          <TextField
            label="Email"
            varian="outlined"
            fullWidth
            name="email"
            value={validation.values.email || ''}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={validation.errors.email && validation.touched.email ? true : false}
          />
          {validation.errors.email && validation.touched.email ? <FormHelperText error>{validation.errors.email}</FormHelperText> : null}
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <Button type="submit" variant="outlined" color="success">
          Update Support User
        </Button>
      </Grid>
      </form>
      <ToastContainer/>
    </>
  );
}

export default SupportEditModal;
