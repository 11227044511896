import React, { useState,useEffect } from 'react';
import { Container, TextField, Button, Box, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {updatePassword} from '../../../Services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

function ResetPassword() {
  const[ids,setId]=useState();
  useEffect(()=>{
    const token = sessionStorage.getItem('authToken');
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
},[])

    const [errors, setErrors] = useState({
        newPasswordError: '',
        passwordMatchError: ''
      });
    
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        reEnterNewPassword: ''
      });
    
      const [showPasswords, setShowPasswords] = useState({
        showCurrentPassword: false,
        showNewPassword: false,
        showReEnterNewPassword: false
      });
    
      const handleChange = (event) => {
        setPasswords({
          ...passwords,
          [event.target.name]: event.target.value
        });
      };

      useEffect(() => {
        let newPasswordError = '';
        let passwordMatchError = '';
    
        if (passwords.newPassword.length > 0 && passwords.newPassword.length < 8) {
          newPasswordError = 'New password must be at least 8 characters long.';
        }
    
        if (passwords.newPassword !== passwords.reEnterNewPassword) {
          passwordMatchError = 'New password and re-entered password do not match.';
        }
    
        setErrors({ newPasswordError, passwordMatchError });
      }, [passwords.newPassword, passwords.reEnterNewPassword]);

      const isFormValid = () => {
        const { currentPassword, newPassword, reEnterNewPassword } = passwords;
        return (
          currentPassword.length > 0 &&
          newPassword.length >= 8 &&
          reEnterNewPassword === newPassword
        );
      };
      
      const handleSubmit = (event) => {
        event.preventDefault();
        if (isFormValid()) {
          
         const data={
          userId:ids,
          current_password:passwords.currentPassword,
          new_password:passwords.newPassword
         }

         updatePassword(data) .then((response) => {
          toast.success("Updated password successfully",{
            autoClose: 2000,
          });
          setTimeout(() => {
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('authToken');
            window.location.href = '/login';
          }, 1000);
        })
        .catch((error) => {
          toast.error("Failed to update password",{
            autoClose: 2000,
          });
        });
        }
      };

  const handleTogglePasswordVisibility = (field) => {
    setShowPasswords({
      ...showPasswords,
      [field]: !showPasswords[field]
    });
  };

  const handleReset = () => {
   window.location.href = '/login'
  };

  return (
    <Container maxWidth="xs" sx={{ display: 'flex', height: '98vh', alignItems: 'center', justifyContent: 'center' }}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }} // Ensures Box uses all available horizontal space in the Container
    >
     <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: '100%' }} // Ensure form Box also takes full width
        >
        <TextField
          margin="normal"
          required
          fullWidth
          name="currentPassword"
          label="Current Password"
          type={showPasswords.showCurrentPassword ? 'text' : 'password'}
          id="current-password"
          value={passwords.currentPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('showCurrentPassword')}
                  edge="end"
                >
                  {showPasswords.showCurrentPassword ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type={showPasswords.showNewPassword ? 'text' : 'password'}
          id="new-password"
          value={passwords.newPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('showNewPassword')}
                  edge="end"
                >
                  {showPasswords.showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.newPasswordError}
            helperText={errors.newPasswordError}/>
        <TextField
          margin="normal"
          required
          fullWidth
          name="reEnterNewPassword"
          label="Re-enter New Password"
          type={showPasswords.showReEnterNewPassword ? 'text' : 'password'}
          id="reenter-new-password"
          value={passwords.reEnterNewPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('showReEnterNewPassword')}
                  edge="end"
                >
                  {showPasswords.showReEnterNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.passwordMatchError}
            helperText={errors.passwordMatchError}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={!isFormValid()}
        >
          Submit
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleReset}
        >
          Cancel
        </Button>
      </Box>
    </Box>
    <ToastContainer/>
  </Container>

  )
}

export default ResetPassword