import { getWalletList } from '../../Services/WalletConfigService';
import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { formatValueIndian } from '../../utils/Formatvalue';
function WalletListModal(props) {
    const { data, modalHandleCallback } = props;
  const[apidata,setApiData]=useState();
console.log(data);
  useEffect(()=>{
fetchWallets(data.user_id);
  },[])
    const fetchWallets = async (user_id) => {
        try {
          const datas = await getWalletList(user_id);
          setApiData(datas?.wallets);
        } catch (error) {
          console.error('Error fetching wallets data: ', error);
        }
      };
  return (
    <Paper elevation={3}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Exchange</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Brokerage</TableCell>
            <TableCell>Wallet Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apidata?.map((row) => (
            <TableRow key={row.exchange}>
              <TableCell>{row.exchange}</TableCell>
              <TableCell>{row.exchange=="NSE" || row.exchange=="MCX"?"₹":"$"}{formatValueIndian(row.balance)}</TableCell>
              <TableCell>{row.exchange=="NSE" || row.exchange=="MCX"?"₹":"$"}{formatValueIndian(row.charges)}</TableCell>
              <TableCell>
      {row.status ? (
        <span style={{ color: 'green' }}>Active</span>
      ) : (
        <span style={{ color: 'red' }}>Inactive</span>
      )}
    </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default WalletListModal